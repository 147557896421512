var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { BaseURLFilter } from "../BaseFilterClasses";
export class BaseSortFilterClass extends BaseURLFilter {
  constructor({
    config,
    overrides,
    onApplyAdditionalOnClick
  }) {
    super({
      config: __spreadProps(__spreadValues({}, config), { text: "Sort", componentProps: null, allowedOperators: ["pick_one"] }),
      overrides,
      onApplyAdditionalOnClick
    });
  }
  getAppliedFilterCount({ currentValue }) {
    return currentValue ? 1 : 0;
  }
  getDefaultMappedOptions() {
    return this.getOptions().map((opt) => ({
      label: opt.label,
      checked: void 0
    }));
  }
  getMappedOptions({
    savedFilter,
    location
  }) {
    const value = savedFilter ? this.getSavedFilterValue({ savedFilter }) : this.getSortSearchParamValue({ location });
    const selectedOption = this.getOptions().find((opt) => opt.queryParamValue === value);
    return this.getDefaultMappedOptions().map((mappedOption) => {
      if ((selectedOption == null ? void 0 : selectedOption.label) === mappedOption.label) {
        mappedOption.checked = "on";
      }
      return mappedOption;
    });
  }
  getShouldShowFilter({ currentParamValue }) {
    if (this.getShouldAlwaysShow()) {
      return true;
    }
    return currentParamValue !== null;
  }
  onOptionChange({
    savedFilter,
    location,
    history,
    newOptions
  }) {
    var _a;
    const searchParams = this.getQueryParamsFromSavedFilter({ savedFilter, location });
    const selectedMappedOption = newOptions.find((opt) => opt.checked === "on");
    const key = this.getFullQueryParamKey();
    if (!selectedMappedOption) {
      searchParams.delete(key);
    } else {
      const foundOption = this.getOptions().find((opt) => opt.label === selectedMappedOption.label);
      if (!foundOption) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, foundOption.queryParamValue);
      }
    }
    (_a = this.getOnApplyAdditionalOnClick()) == null ? void 0 : _a(searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  }
  getSortSearchParamValue({ location }) {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(this.getFullQueryParamKey());
  }
  isValidParamKey(key) {
    return this.getFullQueryParamKey() === key;
  }
  /*
   * The saved filter objects don't have the '[]' suffix that the query params do
   */
  getFullQueryParamKey() {
    return this.getQueryParamKeyPrefix();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSavedFilterValue({ savedFilter }) {
    return savedFilter[this.getFullQueryParamKey()];
  }
  getOptions() {
    return this.getConfig().options;
  }
}
BaseSortFilterClass.type = "sort_filter";
