import moment, { Moment } from "moment";
import { create } from "zustand";
import { addDays, startOfDay, subDays } from "date-fns";

import { shouldShowCalendarRedesignDueToDesktop } from "@pm-assets/js/utils/redesign-routing";
import { AggregatedCalendarEvent } from "./utils/aggregated-calendar-events-utils";
import { CalendarMapMarkerType } from "./maps/utils";
import { CALENDAR_URL_PARAM_KEYS, getCalendarOrMapState } from "./utils/hooks";
import { BaseSavedFiltersFilterClass } from "@pm-frontend/shared/components/FilterButtons/PmSavedFiltersFilter/BaseSavedFiltersFilterClass";
import { BaseSortFilterClass } from "@pm-frontend/shared/components/FilterButtons/PmSortFilter/BaseSortFilterClass";
import {
  getDefaultMeldFilters,
  getMeldSavedFilterConfig,
  MeldFilterQueryKeys,
} from "@pm-frontend/shared/components/FilterButtons/configs/meld-filter-configs";
import { MeldFilterConfigs } from "@pm-frontend/shared/components/FilterButtons/configs/meld-filter-configs";
import { FilterClassTypes } from "@pm-frontend/shared/components/FilterButtons/BaseFilterClasses";
import { BaseSelectableFilterClass } from "@pm-frontend/shared/components/FilterButtons/PmSelectableFilter/BaseSelectableFilterClass";
import { getParamsMatchingFilters } from "@pm-frontend/shared/components/FilterButtons/utils";
import { MeldManagerStatusLabels, MeldStatus, OpenStatuses } from "@pm-frontend/shared/types/meld";
import { useHistory, useLocation } from "react-router-dom";
import { AuthUtils } from "@pm-frontend/shared/utils/auth-utils";

// just a utility type for grabbing two specific variants of AggregatedCalendarEvent union
type ExtractMember<U, T extends U[keyof U]> = U extends { type: T } ? U : never;

const TIMEFRAME_LOCAL_STORAGE_KEY = `meld-calendar-timeframe-${window.PM.user.id}-${window.PM.user.multitenantId}`;
const HIDE_NEARBY_MELD_LOCAL_STORAGE_KEY = `meld-calendar-hide-nearby-melds-m-${AuthUtils.getUserId()}`;

const TIMEFRAMES: Record<string, number> = {
  ONE_DAY: 1,
  THREE_DAY: 3,
  FIVE_DAY: 5,
} as const;

const setInitialSelectedTimeFrame = (timeframe: number) => {
  for (const entry of Object.entries(TIMEFRAMES)) {
    if (timeframe === entry[1]) {
      window.localStorage.setItem(TIMEFRAME_LOCAL_STORAGE_KEY, entry[0]);
      return;
    }
  }
};

const getInitialSelectedTimeFrame = (): number => {
  const value = window.localStorage.getItem(TIMEFRAME_LOCAL_STORAGE_KEY);
  if (!value) {
    return 1;
  }
  return TIMEFRAMES[value] || 1;
};

const getInitialHideNearbyMeldsState = (): boolean => {
  const value = window.localStorage.getItem(HIDE_NEARBY_MELD_LOCAL_STORAGE_KEY);
  if (value === "true") {
    return true;
  }
  return false;
};

const setInitialHideNearbyMeldsState = (state: boolean) => {
  window.localStorage.setItem(HIDE_NEARBY_MELD_LOCAL_STORAGE_KEY, state.toString());
};

const CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY = `meld-calendar-selected-agents-${window.PM.user.id}-${window.PM.user.multitenantId}`;

const setInitialSelectedVendorsOrAgents = (state: CalendarSelectedAgentsVendorsIds) =>
  window.localStorage.setItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY, JSON.stringify(state));

const getInitialSelectedVendorsOrAgents = (): CalendarSelectedAgentsVendorsIds => {
  const value = window.localStorage.getItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY);

  let result: CalendarSelectedAgentsVendorsIds = { agents: [], vendors: [] };

  if (value) {
    try {
      const parsed = JSON.parse(value);
      if (Array.isArray(parsed.agents) && Array.isArray(parsed.vendors)) {
        result = parsed;
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  return result;
};

interface CalendarSelectedAgentsVendorsIds {
  agents: number[];
  vendors: number[];
}

interface CalendarRecommendEvent {
  personaId: number;
  personaType: "agent" | "vendor";
  previousEvent: {
    id: number;
    type: "management_scheduled" | "alternative_event_scheduled";
  } | null;
  selectedAppointment: {
    start: moment.Moment;
    end: moment.Moment;
  };
}

interface AltEventSelectedAgentsTimes {
  selectedAgents: number[];
  // set if we are editing an event
  eventId?: number;
  eventDescription?: string;
  selectedTime: {
    date: Date;
    start?: Date | undefined;
    end?: Date | undefined;
  };
}
interface MapBoxLocation {
  mapbox_id: string;
  original_string: string;
  name: string;
  full_address: string;
  latitude: number;
  longitude: number;
}

interface OutlookCalendarEvent {
  id: string;
  isAllDay: boolean;
  isCancelled: boolean;
  subject: string | undefined;
  locations: object[] | undefined;
  start: {
    dateTime?: string;
    timeZone?: string;
  };
  end: {
    dateTime?: string;
    timeZone?: string;
  };
}

interface CalendarState {
  selectedDate: number;
  selectedCalendarTimeFrame: number;
  hideNearbyMelds: boolean;
  selectedVendorsAgentsIds: CalendarSelectedAgentsVendorsIds;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scheduledSegments: any;
  dragAndDropState: "resizingEvent" | "draggingMeld" | "draggingEvent" | null;
  pendingResdientAvailabilities: Array<ExtractMember<AggregatedCalendarEvent, "pending_offered_availability">>;
  altEventSelectedAgentsTime: AltEventSelectedAgentsTimes | null;
  pendingRecommendedMeld: CalendarRecommendEvent | null;
  // passing this via url is such a pain we keep it in state
  mapEventListRightpaneItems: CalendarMapMarkerType[];
  // since both use the url to filter we need to store/retrieve the prior state
  // when switching between the calendar and the map
  // we initialize 'read-only' classes which lack populated filter options, but
  // can read from the url
  meldFilterParams: {
    calendar: {
      filterClasses: FilterClassTypes[];
      savedFilterClass: BaseSavedFiltersFilterClass;
      sortFilterClass: BaseSortFilterClass;
      type: "read-only" | "read-write";
      priorParams: URLSearchParams;
    };
    map: {
      filterClasses: FilterClassTypes[];
      type: "read-only" | "read-write";
      priorParams: URLSearchParams;
    };
  };
  onApplyFilterAdditionalOnClick: (arg0: URLSearchParams) => void;
  actions: {
    setPendingRecommendEvent: (event: CalendarRecommendEvent | null) => void;
    setmapEventListRightpaneItems: (items: CalendarMapMarkerType[]) => void;
    // date and timeframe
    setSelectedDate: (newDate: number, isMobile: boolean) => void;
    // some screens override the selected timeframe state, so we take it as an input
    incrementDate: (selectedTimeFrame: number) => void;
    decrementDate: (selectedTimeFrame: number) => void;
    setDateToToday: () => void;
    setCalendarSelectedTimeFrame: (newTimeFrame: number) => void;
    setHideNearbyMelds: (hide: boolean) => void;
    // selected vendors and agents - add is for extending the current lists
    setSelectedVendorsAgents: (selected: CalendarSelectedAgentsVendorsIds) => void;
    addSelectedVendorsAgents: (selected: CalendarSelectedAgentsVendorsIds) => void;
    // when the component unmounts we want to reset the state
    resetState: () => void;
    // selected resident availabilities
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    addScheduledSegment: (segment: any) => void;
    removeScheduledSegment: (id: number) => void;
    // drag and drop state
    startDraggingMeld: () => void;
    startDraggingEvent: () => void;
    stopDragging: () => void;
    startResizingEvent: () => void;
    stopResizingEvent: () => void;
    // click to add availabilities and their calendar placeholders
    addPendingResidentAvailability: (
      eventsToAdd: Array<{
        tempId: string;
        agentId: number;
        start: string;
        end: string;
      }>
    ) => void;
    // pending resident availabilities
    upsertPendingResidentAvailability: (props: {
      tempId: string;
      start: Moment;
      end: Moment;
      agents: Array<{ id: number }>;
    }) => void;
    removePendingResidentAvailability: (tempId: string) => void;
    clearPendingResidentAvailabilities: () => void;
    // click to set alternative event form
    setAltEventSelectedAgentsTime: (props: {
      newAgents?: AltEventSelectedAgentsTimes["selectedAgents"];
      newSelectedTime?: Partial<AltEventSelectedAgentsTimes["selectedTime"]>;
      eventId?: number;
      eventDescription?: string;
    }) => void;
    clearAltEventSelectedAgentsTime: (arg0?: boolean) => void;
    // reading writing calendar/map meld filters stored in url
    setMeldFilterParams: (arg0: {
      newCalendar?: CalendarState["meldFilterParams"]["calendar"];
      newMap?: CalendarState["meldFilterParams"]["map"];
    }) => void;
    // initial filters don't have backend populated options, after those
    // are loaded this is called to update the stored filters
    populateMeldFilterParamsOptions: (
      arg0: (state: CalendarState) => CalendarState["meldFilterParams"]["calendar"]
    ) => void;
    // when mounting this page we want to apply the default meld filter params
    // if there are none
    initializePageWithMeldParam: (arg0: {
      location: ReturnType<typeof useLocation>;
      history: ReturnType<typeof useHistory>;
    }) => void;
  };
}

// we store a string of the query parameters as the value. This gets parsed
// for actual meld filter params later
const CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY = `calendar-meld-list-filter-params-user-${AuthUtils.getUserId()}-v1`;
const MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY = `map-meld-list-filter-params-user-${AuthUtils.getUserId()}-v1`;

function getInitialMeldFilterParams(type: "map" | "calendar"): URLSearchParams | undefined {
  const key = type === "map" ? MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY : CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY;
  try {
    const rawValue = window.localStorage.getItem(key);
    if (rawValue) {
      return new URLSearchParams(rawValue);
    }
  } catch {
    return undefined;
  }
  return undefined;
}

function writeMeldFilterParamsToStorage(type: "map" | "calendar", params: URLSearchParams) {
  const key = type === "map" ? MAP_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY : CALENDAR_MELD_FILTER_PARAMS_LOCAL_STORAGE_KEY;
  try {
    window.localStorage.setItem(key, params.toString());
  } catch {
    return;
  }
}

// do not export - actions are the interface for interacting with the state
const useCalendarStateStore = create<CalendarState>((set, get) => {
  // when the filters change we need to update our priorParams
  // so that navigating away/to the calendar preserves our selections
  const onApplyAdditionalOnClick = (newParams: URLSearchParams) => {
    const currentFullParams = new URLSearchParams(window.location.search);
    if (currentFullParams.has(CALENDAR_URL_PARAM_KEYS.MAP_OPEN)) {
      // map is open
      // update url if no meld filter params are present, and set prior params to this value
      set((state) => {
        const [existingMapMeldFilterParams] = getParamsMatchingFilters({
          existingParams: newParams,
          filters: state.meldFilterParams.map.filterClasses,
        });
        writeMeldFilterParamsToStorage("map", existingMapMeldFilterParams);
        return {
          meldFilterParams: {
            ...state.meldFilterParams,
            map: { ...state.meldFilterParams.map, priorParams: existingMapMeldFilterParams },
          },
        };
      });
    } else {
      // calendar is open
      set((state) => {
        // update url if no meld filter params are present, and set prior params to this value
        const [existingCalendarMeldFilterParams] = getParamsMatchingFilters({
          existingParams: newParams,
          filters: state.meldFilterParams.calendar.filterClasses,
          sortFilter: state.meldFilterParams.calendar.sortFilterClass,
          savedFilter: state.meldFilterParams.calendar.savedFilterClass,
        });
        writeMeldFilterParamsToStorage("calendar", existingCalendarMeldFilterParams);
        return {
          meldFilterParams: {
            ...state.meldFilterParams,
            calendar: { ...state.meldFilterParams.calendar, priorParams: existingCalendarMeldFilterParams },
          },
        };
      });
    }
  };
  // we initialize meldFilterParams with readonly instances of the filter classes
  // upon component mount (and api requests to get options) these will be replaced with
  // filter classes populated with options
  const { sortFilter: calendarMeldListSortFilter, filters: calendarMeldListFilters } = getDefaultMeldFilters({
    allMaint: [],
    onApplyAdditionalOnClick,
  });

  const meldCalendarNearbyMeldFilters = [
    new BaseSelectableFilterClass({
      config: MeldFilterConfigs.selectable.status,
      overrides: {
        alwaysShow: true,
        // we limit status filtering options for nearby melds
        options: OpenStatuses.map((status: MeldStatus) => ({
          label: MeldManagerStatusLabels[status],
          queryParamValue: status,
        })),
      },
      onApplyAdditionalOnClick,
    }),
    new BaseSelectableFilterClass({
      config: MeldFilterConfigs.selectable.priority,
      overrides: { alwaysShow: true },
      onApplyAdditionalOnClick,
    }),
  ];

  const calendarMeldListSavedFilter = new BaseSavedFiltersFilterClass({
    config: getMeldSavedFilterConfig({
      savedFilters: [],
      otherFilters: [calendarMeldListSortFilter, ...calendarMeldListFilters],
    }),
    overrides: { alwaysShow: true },
  });

  const meldFilterParams: CalendarState["meldFilterParams"] = {
    calendar: {
      sortFilterClass: calendarMeldListSortFilter,
      savedFilterClass: calendarMeldListSavedFilter,
      filterClasses: calendarMeldListFilters,
      type: "read-only",
      // these are the default
      priorParams:
        getInitialMeldFilterParams("calendar") ||
        new URLSearchParams({
          [MeldFilterQueryKeys.status]: OpenStatuses.filter((status) => status !== MeldStatus.PENDING_COMPLETION).join(
            ","
          ),
        }),
    },
    map: {
      // since these filter classes don't require option populate from
      // api class we can initialize it as 'read-write'
      filterClasses: meldCalendarNearbyMeldFilters,
      type: "read-write",
      // these are the default
      priorParams:
        getInitialMeldFilterParams("map") ||
        new URLSearchParams({
          [MeldFilterQueryKeys.status]: [MeldStatus.PENDING_ASSIGNMENT].join(","),
        }),
    },
  };

  return {
    activePane: shouldShowCalendarRedesignDueToDesktop.matches ? { type: "meldsToSchedule" } : { type: "mobile-null" },
    selectedDate: startOfDay(new Date()).valueOf(),
    hideNearbyMelds: getInitialHideNearbyMeldsState(),
    calendarOrMap: "calendar",
    selectedCalendarTimeFrame: getInitialSelectedTimeFrame(),
    selectedVendorsAgentsIds: getInitialSelectedVendorsOrAgents(),
    scheduledSegments: [],
    dragAndDropState: null,
    pendingResdientAvailabilities: [],
    pendingRecommendedMeld: null,
    mapEventListRightpaneItems: [],
    altEventSelectedAgentsTime: null,
    priorRightPaneType: null,
    // since both use the url to filter we need to store/retrieve the prior state
    // when switching between the calendar and the map
    // we initialize 'read-only' classes which lack populated filter options, but
    // can read from the url
    meldFilterParams,
    onApplyFilterAdditionalOnClick: onApplyAdditionalOnClick,
    actions: {
      setPendingRecommendEvent: (event) => set({ pendingRecommendedMeld: event }),
      setmapEventListRightpaneItems: (items) => set({ mapEventListRightpaneItems: items }),
      setSelectedDate: (newDate) => {
        set({ selectedDate: newDate, pendingRecommendedMeld: null });
      },
      incrementDate: (selectedCalendarTimeFrame: number) =>
        set((state) => ({
          selectedDate: addDays(new Date(state.selectedDate), selectedCalendarTimeFrame).valueOf(),
          pendingRecommendedMeld: null,
        })),
      decrementDate: (selectedCalendarTimeFrame: number) =>
        set((state) => ({
          selectedDate: subDays(new Date(state.selectedDate), selectedCalendarTimeFrame).valueOf(),
          pendingRecommendedMeldPlaceholder: null,
        })),
      setDateToToday: () =>
        set({
          selectedDate: startOfDay(new Date()).valueOf(),
          pendingRecommendedMeld: null,
        }),
      setCalendarSelectedTimeFrame: (newTimeFrame) => {
        setInitialSelectedTimeFrame(newTimeFrame);
        set({
          selectedCalendarTimeFrame: newTimeFrame,
          // for simplicity on timeframe change we just clear out any recommended meld events
          pendingRecommendedMeld: null,
        });
      },
      setHideNearbyMelds: (newValue) => {
        setInitialHideNearbyMeldsState(newValue);
        set({ hideNearbyMelds: newValue });
      },
      setSelectedVendorsAgents: (selected) => {
        setInitialSelectedVendorsOrAgents(selected);
        set((state) => {
          // if the recommended meld agent isn't among the selected agent we revert to the melds list page
          if (state.pendingRecommendedMeld) {
            const currentAgentId = state.pendingRecommendedMeld.personaId;
            if (!selected.agents.some((agentId) => agentId === currentAgentId)) {
              return { selectedVendorsAgentsIds: selected, activePane: { type: "meldsToSchedule" } };
            }
          }
          return { selectedVendorsAgentsIds: selected };
        });
      },
      addSelectedVendorsAgents: (agentsVendorsToAdd) => {
        set((state) => {
          const result = {
            agents: state.selectedVendorsAgentsIds.agents,
            vendors: state.selectedVendorsAgentsIds.vendors,
          };
          agentsVendorsToAdd.agents.forEach((agent) => {
            if (!result.agents.includes(agent)) {
              result.agents.push(agent);
            }
          });
          agentsVendorsToAdd.vendors.forEach((vendor) => {
            if (!result.vendors.includes(vendor)) {
              result.vendors.push(vendor);
            }
          });
          return { selectedVendorsAgentsIds: result };
        });
      },
      resetState: () =>
        set({
          pendingRecommendedMeld: null,
          pendingResdientAvailabilities: [],
          mapEventListRightpaneItems: [],
          selectedDate: startOfDay(new Date()).valueOf(),
          dragAndDropState: null,
        }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      addScheduledSegment: (segment: any) =>
        set((state) => ({
          scheduledSegments: [...state.scheduledSegments, segment],
        })),
      removeScheduledSegment: (id: number) =>
        set((state) => ({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          scheduledSegments: state.scheduledSegments.filter((segment: any) => segment.id !== id),
        })),
      startDraggingMeld: () => set({ dragAndDropState: "draggingMeld" }),
      startDraggingEvent: () => set({ dragAndDropState: "draggingEvent" }),
      stopDragging: () => set({ dragAndDropState: null }),
      startResizingEvent: () => set({ dragAndDropState: "resizingEvent" }),
      stopResizingEvent: () => set({ dragAndDropState: null }),
      // pending resident availability placeholders
      addPendingResidentAvailability: (pendingEventsToAdd) =>
        set((state) => {
          return {
            pendingResdientAvailabilities: [
              ...state.pendingResdientAvailabilities,
              ...pendingEventsToAdd.map(
                (event) =>
                  ({
                    type: "pending_offered_availability",
                    start: event.start,
                    end: event.end,
                    startDate: new Date(event.start),
                    endDate: new Date(event.end),
                    start_moment: moment(event.start),
                    end_moment: moment(event.end),
                    description: "Offer this time to resident",
                    personaId: event.agentId,
                    personaType: "agent",
                    key: event.tempId + event.agentId,
                    tempId: event.tempId,
                  } as const)
              ),
            ],
          };
        }),
      removePendingResidentAvailability: (tempId) =>
        set((state) => {
          return {
            pendingResdientAvailabilities: [...state.pendingResdientAvailabilities].filter(
              (e) => e.type !== "pending_offered_availability" || e.tempId !== tempId
            ),
          };
        }),
      upsertPendingResidentAvailability: ({ tempId, start, end, agents }) =>
        set((state) => {
          let updatedAnEvent = false;
          // we map the existing set because if a meld is assigned to multiple techs there can be multiple events
          // with the same tempId
          const updatedAvailabilities = state.pendingResdientAvailabilities.map((existingEvent) => {
            if (existingEvent.type !== "pending_offered_availability" || existingEvent.tempId !== tempId) {
              return existingEvent;
            }
            updatedAnEvent = true;
            const updatedEvent = { ...existingEvent };
            updatedEvent.end_moment = end.clone();
            updatedEvent.end = end.toISOString();
            updatedEvent.start_moment = start.clone();
            updatedEvent.start = start.toISOString();
            return updatedEvent;
          });
          // handle insertions
          if (!updatedAnEvent) {
            agents.forEach((agent) => {
              updatedAvailabilities.push({
                type: "pending_offered_availability",
                start: start.toISOString(),
                startDate: start.toDate(),
                end: end.toISOString(),
                endDate: end.toDate(),
                start_moment: start,
                end_moment: end,
                description: "Offer this time to resident",
                personaId: agent.id,
                personaType: "agent",
                key: tempId + agent.id,
                tempId,
              });
            });
          }
          return { pendingResdientAvailabilities: updatedAvailabilities };
        }),
      clearPendingResidentAvailabilities: () => set({ pendingResdientAvailabilities: [] }),
      // calendar/map meld filter params
      setMeldFilterParams: (props) =>
        set((state) => {
          const map = "newMap" in props && !!props.newMap ? props.newMap : state.meldFilterParams.map;
          const calendar =
            "newCalendar" in props && !!props.newCalendar ? props.newCalendar : state.meldFilterParams.calendar;
          return {
            meldFilterParams: {
              map,
              calendar,
            },
          };
        }),
      populateMeldFilterParamsOptions: (updateFunc) =>
        set((state) => {
          if (state.meldFilterParams.calendar.type === "read-write") {
            return {};
          }
          const newCalendarState = updateFunc(state);

          return {
            meldFilterParams: {
              map: state.meldFilterParams.map,
              calendar: newCalendarState,
            },
          };
        }),
      initializePageWithMeldParam: ({ location, history }) => {
        const meldFilterParamsState = get().meldFilterParams;
        const currentParams = new URLSearchParams(location.search);
        if (currentParams.has(CALENDAR_URL_PARAM_KEYS.MAP_OPEN)) {
          // map is open
          // update url if no meld filter params are present, and set prior params to this value
          const [existingMapMeldFilterParams, existingMapMeldFilterParamsHasValues] = getParamsMatchingFilters({
            existingParams: currentParams,
            filters: meldFilterParamsState.map.filterClasses,
          });
          if (existingMapMeldFilterParamsHasValues) {
            meldFilterParamsState.map.priorParams = existingMapMeldFilterParams;
          } else {
            // we add the default filter params
            // @ts-expect-error we can in fact iterate
            for (const [key, value] of meldFilterParamsState.map.priorParams) {
              currentParams.set(key, value);
            }
            history.replace({ pathname: location.pathname, search: currentParams.toString() });
          }
        } else {
          // calendar is open
          // update url if no meld filter params are present, and set prior params to this value
          const [existingCalendarMeldFilterParams, existingCalendarMeldFilterParamsHasValues] =
            getParamsMatchingFilters({
              existingParams: currentParams,
              filters: meldFilterParamsState.calendar.filterClasses,
              sortFilter: meldFilterParamsState.calendar.sortFilterClass,
              savedFilter: meldFilterParamsState.calendar.savedFilterClass,
            });
          if (existingCalendarMeldFilterParamsHasValues) {
            meldFilterParamsState.calendar.priorParams = existingCalendarMeldFilterParams;
          } else {
            // we add the stored filter params
            // @ts-expect-error we can in fact iterate
            for (const [key, value] of meldFilterParamsState.calendar.priorParams) {
              currentParams.set(key, value);
            }
            history.replace({ pathname: location.pathname, search: currentParams.toString() });
          }
        }
      },
      // click to add alt events
      setAltEventSelectedAgentsTime: ({ newAgents, newSelectedTime, eventId, eventDescription }) =>
        set((state) => {
          const selectedAgents = newAgents || state.altEventSelectedAgentsTime?.selectedAgents || [];
          const selectedDate =
            (newSelectedTime && "date" in newSelectedTime
              ? newSelectedTime.date
              : state.altEventSelectedAgentsTime?.selectedTime.date) || new Date();
          const selectedStart =
            newSelectedTime && "start" in newSelectedTime
              ? newSelectedTime.start
              : state.altEventSelectedAgentsTime?.selectedTime.start;
          const selectedEnd =
            newSelectedTime && "end" in newSelectedTime
              ? newSelectedTime.end
              : state.altEventSelectedAgentsTime?.selectedTime.end;

          if (!newAgents && !newSelectedTime) {
            return { altEventSelectedAgentsTime: null };
          }
          return {
            altEventSelectedAgentsTime: {
              selectedTime: { date: selectedDate, start: selectedStart, end: selectedEnd },
              eventId: eventId || state.altEventSelectedAgentsTime?.eventId,
              eventDescription: eventDescription || state.altEventSelectedAgentsTime?.eventDescription,
              selectedAgents,
            },
          };
        }),
      clearAltEventSelectedAgentsTime: (removeEvent = false) => {
        if (removeEvent) {
          set(() => ({ altEventSelectedAgentsTime: null }));
        } else {
          set((state) => ({
            altEventSelectedAgentsTime: {
              ...(state.altEventSelectedAgentsTime || []),
              selectedAgents: state.altEventSelectedAgentsTime?.selectedAgents || [],
              selectedTime: {
                date: state.altEventSelectedAgentsTime?.selectedTime.date || new Date(),
                start: undefined,
                end: undefined,
              },
            },
          }));
        }
      },
    },
  };
});

const useCalendarStateMeldFilterParams = () => useCalendarStateStore((state) => state.meldFilterParams);
const useCalendarStateMeldFilterApplyOnClick = () =>
  useCalendarStateStore((state) => state.onApplyFilterAdditionalOnClick);

const useCalendarStatePendingRecommendedMeld = () => useCalendarStateStore((state) => state.pendingRecommendedMeld);
const useCalendarStateAltEventSelectedAgentsTime = () =>
  useCalendarStateStore((state) => state.altEventSelectedAgentsTime);
const useCalendarStateMapEventListRightpaneItems = () =>
  useCalendarStateStore((state) => state.mapEventListRightpaneItems);
const useCalendarStatePendingOfferedAvailabilities = () =>
  useCalendarStateStore((state) => state.pendingResdientAvailabilities);
const useCalendarStateSelectedDate = () => useCalendarStateStore((state) => state.selectedDate);
const useCalendarStateHideNearbyMelds = () => useCalendarStateStore((state) => state.hideNearbyMelds);

const useCalendarStateSelectedTimeFrame = (isMobile: boolean) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const calendarOrMap = getCalendarOrMapState(searchParams, isMobile);

  return useCalendarStateStore((state) => {
    if (isMobile) {
      return 5;
    } else if (calendarOrMap === "large_map") {
      return 1;
    } else {
      return state.selectedCalendarTimeFrame;
    }
  });
};

const useCalendarStateSelectedVendorAgentIds = (isMobile: boolean): CalendarSelectedAgentsVendorsIds =>
  useCalendarStateStore((state) => {
    if (isMobile) {
      if (state.selectedVendorsAgentsIds.agents.length > 0) {
        return { agents: [state.selectedVendorsAgentsIds.agents[0]], vendors: [] };
      } else if (state.selectedVendorsAgentsIds.vendors.length > 0) {
        return { agents: [], vendors: [state.selectedVendorsAgentsIds.vendors[0]] };
      }
    }
    return state.selectedVendorsAgentsIds;
  });
const useCalendarStateActions = () => useCalendarStateStore((state) => state.actions);
const useCalendarStateScheduledSegments = () => useCalendarStateStore((state) => state.scheduledSegments);
const useCalendarDragAndDropState = () => useCalendarStateStore((state) => state.dragAndDropState);

export {
  useCalendarStateMeldFilterParams,
  useCalendarStateMeldFilterApplyOnClick,
  useCalendarStatePendingOfferedAvailabilities,
  useCalendarStateMapEventListRightpaneItems,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStatePendingRecommendedMeld,
  useCalendarStateActions,
  useCalendarStateSelectedDate,
  useCalendarStateHideNearbyMelds,
  useCalendarStateSelectedTimeFrame,
  useCalendarStateSelectedVendorAgentIds,
  useCalendarStateScheduledSegments,
  useCalendarDragAndDropState,
  CalendarRecommendEvent,
  OutlookCalendarEvent,
  CalendarSelectedAgentsVendorsIds,
  AltEventSelectedAgentsTimes,
  CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY,
  CalendarState,
  MapBoxLocation,
};
