import { useHistory, useLocation } from "react-router-dom";

import { BaseComboboxFilterClass } from "./PmComboboxFilter/BaseComboboxFilterClass";
import { BaseDateFilterClass } from "./PmDateFilter/BaseDateFilterClass";
import { BaseDecimalFilterClass } from "./PmDecimalFilter/BaseDecimalFilterClass";
import { BaseSavedFiltersFilterClass } from "./PmSavedFiltersFilter/BaseSavedFiltersFilterClass";
import { BaseSelectableFilterClass } from "./PmSelectableFilter/BaseSelectableFilterClass";
import { BaseSortFilterClass } from "./PmSortFilter/BaseSortFilterClass";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SavedFilterObject = Record<string, any[] | any> | undefined;

// make all subfields of a type partial
export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

// Deep merge function that handles arbitrary object types
function deepMerge<T extends object>(target: T, source: DeepPartial<T>): T {
  const output = { ...target }; // Start with a shallow copy of the target

  for (const key in source) {
    // Ignore undefined source values
    if (source[key] === undefined) {
      continue;
    }

    if (isObject(output[key]) && isObject(source[key])) {
      // If both target and source values are objects, recursively merge them
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      output[key] = deepMerge(output[key] as object, source[key] as object) as any;
    } else {
      // Otherwise, directly assign the source value
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      output[key] = source[key] as any;
    }
  }

  return output;
}

// Utility function to check if a value is an object
function isObject(item: unknown): item is object {
  return item !== null && typeof item === "object" && !Array.isArray(item);
}

/**
 * The base class that all filter classes must extend. Has some methods
 * all filters use, as well as requiring the methods used by PmFilterButtons
 * be implemented for all subclasses
 * */
export class BaseURLFilter<
  AllowedOperators extends string,
  ComponentProps,
  FilterConfig extends {
    queryParamKeyPrefix: string;
    filterName: string;
    text: string;
    popoverWidth: string;
    allowedOperators: AllowedOperators[];
    componentProps: ComponentProps;
    alwaysShow?: boolean;
    // if the consumer needs to also do some work when filters change on its
    // page
    onApplyAdditionalOnClick?: (params: URLSearchParams) => void;
  }
> {
  /**
          Given an array of filters, delete all associated filter [key,value]s
          */
  static deleteAllFilterValues(
    props: {
      filters: FilterClassTypes[];
      savedFilterClass?: BaseSavedFiltersFilterClass;
      sortFilter?: BaseSortFilterClass;
    } & (
      | {
          location: ReturnType<typeof useLocation>;
          paramsToMutate?: never;
        }
      | {
          location?: ReturnType<typeof useLocation>;
          paramsToMutate: URLSearchParams;
        }
    )
  ): URLSearchParams {
    const result = props.paramsToMutate || new URLSearchParams(props.location.search);

    if (props.filters) {
      // if the param matches a filter we keep it in existingMeldFilterParams
      for (const filter of props.filters) {
        filter.deleteAllParamValues({ paramsToMutate: result });
      }
    }
    // and check for saved_filter as well
    if (props.savedFilterClass) {
      props.savedFilterClass.deleteAllParamValues({ paramsToMutate: result });
    }
    // and check for saved_filter as well
    if (props.sortFilter) {
      props.sortFilter.deleteAllParamValues({ paramsToMutate: result });
    }
    return result;
  }
  /**
          Given an array of filters, get the currently applied params
          */
  static getFilterValues(
    props: {
      filters: FilterClassTypes[];
      savedFilterClass?: BaseSavedFiltersFilterClass;
      sortFilter?: BaseSortFilterClass;
      savedFilter: SavedFilterObject;
    } & (
      | {
          location: ReturnType<typeof useLocation>;
          paramsToMutate?: never;
          existingParams?: never;
        }
      | {
          location?: ReturnType<typeof useLocation>;
          paramsToMutate: URLSearchParams;
          existingParams: URLSearchParams;
        }
    )
  ): URLSearchParams {
    const currentParams = props.existingParams || new URLSearchParams(location.search);
    const result = props.paramsToMutate || new URLSearchParams();

    if (props.filters) {
      // if the param matches a filter we keep it in existingMeldFilterParams
      for (const filter of props.filters) {
        if (props.savedFilter) {
          filter.getQueryParamsFromSavedFilter({ savedFilter: props.savedFilter, paramsToMutate: result });
        } else {
          filter.getQueryParamValuesFromURL({ existingParams: currentParams, target: "url", paramsToMutate: result });
        }
      }
    }
    // and check for saved_filter as well
    if (props.savedFilterClass) {
      if (props.savedFilter) {
        props.savedFilterClass.getQueryParamsFromSavedFilter({
          savedFilter: props.savedFilter,
          paramsToMutate: result,
        });
      } else {
        props.savedFilterClass.getQueryParamValuesFromURL({
          existingParams: currentParams,
          target: "url",
          paramsToMutate: result,
        });
      }
    }
    // and check for saved_filter as well
    if (props.sortFilter) {
      if (props.savedFilter) {
        props.sortFilter.getQueryParamsFromSavedFilter({ savedFilter: props.savedFilter, paramsToMutate: result });
      } else {
        props.sortFilter.getQueryParamValuesFromURL({
          existingParams: currentParams,
          target: "url",
          paramsToMutate: result,
        });
      }
    }
    return result;
  }

  private static SAVED_FILTER_IGNORED_FIELDS = [
    "id",
    "name",
    "filterset_fields",
    "position",
    "private",
    "current_agent_default",
    "limit",
    "offset",
  ];
  protected config: FilterConfig;
  protected onApplyAdditionalOnClick: ((params: URLSearchParams) => void) | undefined;

  constructor({
    config,
    overrides,
    onApplyAdditionalOnClick,
  }: {
    config: FilterConfig;
    overrides?: DeepPartial<FilterConfig>;
    onApplyAdditionalOnClick?: (params: URLSearchParams) => void;
  }) {
    const _config = overrides ? deepMerge(config, overrides) : config;
    this.config = _config;
    this.onApplyAdditionalOnClick = onApplyAdditionalOnClick;
  }

  /***
   * Operators such as 'All of', 'None of', 'Between', 'Missing' etc
   */
  getAllowedOperatorOptions() {
    return this.getAllowedOperators().map((opt) => {
      return {
        label: opt,
        value: opt,
        dropdownDisplay: opt,
        inputDisplay: opt,
      };
    });
  }

  getButtonText() {
    return this.getConfig().text;
  }

  getFilterName() {
    return this.getConfig().filterName;
  }

  getOnClearClick({
    location,
    closePopover,
    history,
    savedFilter,
  }: {
    location: ReturnType<typeof useLocation>;
    history: ReturnType<typeof useHistory>;
    savedFilter: SavedFilterObject;
    closePopover: () => void;
  }) {
    return () => {
      const savedSearchParams = this.getQueryParamsFromSavedFilter({ savedFilter, location });
      this.deleteAllParamValues({ paramsToMutate: savedSearchParams });
      this.getOnApplyAdditionalOnClick()?.(savedSearchParams);
      history.replace({
        pathname: location.pathname,
        search: savedSearchParams.toString(),
      });
      closePopover();
    };
  }

  getPopoverWidth() {
    return "350px";
  }

  getQueryParamsFromSavedFilter({
    savedFilter,
    location,
    paramsToMutate,
  }: {
    savedFilter: Record<string, string | string[] | null> | undefined;
  } & (
    | {
        location: ReturnType<typeof useLocation>;
        paramsToMutate?: never;
      }
    | {
        // optimization for methods where we call this often and want to avoid
        // initializing `searchParams` each time
        location?: never;
        paramsToMutate: URLSearchParams;
      }
  )): URLSearchParams {
    if (savedFilter === undefined) {
      const newParams = paramsToMutate || new URLSearchParams(location.search);
      // handles cases where the `saved_filter` param value isn't a real filter id
      // or is 'default' and the user doesn't have a default filter
      newParams.delete("saved_filter");
      return newParams;
    }
    const searchParams = paramsToMutate || new URLSearchParams();
    for (const entry of Object.entries(savedFilter)) {
      const [key, value] = entry;
      if (BaseURLFilter.SAVED_FILTER_IGNORED_FIELDS.includes(key)) {
        continue;
      }
      if (value === "" || value === null || (Array.isArray(value) && value.length === 0)) {
        continue;
      }

      if (Array.isArray(value)) {
        searchParams.set(key + "[]", value.join(","));
      } else {
        searchParams.append(key, value);
      }
    }

    return searchParams;
  }

  getQueryParamValuesFromURL(
    props: {
      target: "url" | "savedFilter";
      paramsToMutate?: URLSearchParams;
    } & (
      | {
          location: ReturnType<typeof useLocation>;
          existingParams?: never;
        }
      | {
          location?: never;
          existingParams: URLSearchParams;
        }
    )
  ): URLSearchParams {
    const currentURLParams = props.existingParams || new URLSearchParams(props.location.search);
    const newParams = props.paramsToMutate || new URLSearchParams();
    for (const operator of this.getAllowedOperators()) {
      const paramKey = this.getFullQueryParamKey({ operator, target: props.target });
      if (!paramKey) {
        continue;
      }
      const value = currentURLParams.get(paramKey);
      if (value) {
        newParams.set(paramKey, value);
      }
    }

    return newParams;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAppliedFilterCount(_props: unknown) {
    throw new Error("This method must be implemented by sub-classes");
  }

  getShouldAlwaysShow() {
    return !!this.getConfig().alwaysShow;
  }

  deleteAllParamValues(
    props:
      | {
          location: ReturnType<typeof useLocation>;
          paramsToMutate?: never;
        }
      | {
          location?: never;
          paramsToMutate: URLSearchParams;
        }
  ): URLSearchParams {
    const searchParams = props.paramsToMutate || new URLSearchParams(props.location.search);
    for (const operator of this.getAllowedOperators()) {
      const key = this.getFullQueryParamKey({ operator, target: "url" });
      if (key) {
        searchParams.delete(key);
      }
    }
    return searchParams;
  }

  isValidParamKey(key: string): boolean {
    for (const operator of this.getAllowedOperators()) {
      if (this.getFullQueryParamKey({ operator, target: "url" }) === key) {
        return true;
      }
    }
    return false;
  }

  protected getAllowedOperators() {
    return this.getConfig().allowedOperators;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected getFullQueryParamKey(_props: { operator: string; target: "savedFilter" | "url" }): string | null {
    throw new Error("This method must be implemented by sub-classes");
  }

  protected getQueryParamKeyPrefix() {
    return this.getConfig().queryParamKeyPrefix;
  }

  protected getConfig() {
    return this.config;
  }

  protected getOnApplyAdditionalOnClick() {
    return this.onApplyAdditionalOnClick;
  }

  protected getSavedFilterValue({
    savedFilter,
    operator,
  }: {
    savedFilter: NonNullable<SavedFilterObject>;
    operator: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): any[] | any {
    const key = this.getFullQueryParamKey({ operator, target: "savedFilter" });
    return key ? savedFilter[key] : undefined;
  }

  protected getSearchParamValue({
    operator,
    location,
    currentSearchParams,
  }:
    | {
        location: ReturnType<typeof useLocation>;
        operator: string;
        currentSearchParams?: never;
      }
    | {
        // optimization for methods where we call this often and want to avoid
        // initializing `searchParams` each time
        location?: never;
        operator: string;
        currentSearchParams: URLSearchParams;
      }) {
    const searchParams = currentSearchParams || new URLSearchParams(location.search);
    const key = this.getFullQueryParamKey({ operator, target: "url" });
    return key ? searchParams.get(key) : undefined;
  }
}

export type FilterClassTypes =
  | BaseSelectableFilterClass
  | BaseComboboxFilterClass
  | BaseDecimalFilterClass
  | BaseDateFilterClass;
