export const SEARCH_BOX_DEBOUNCE_IN_MS = 200;

export const ERROR_SUPPORT_MESSAGE =
  "Sorry, it looks like we are having a problem. If the problem persists please contact us at support@propertymeld.com";

export const ERROR_NETWORK_MESSAGE =
  "Oops, we could not complete your request. Check your internet connection and try again.";

export const ERROR_403 =
  "Oops, we could not complete your request. It looks like you do not have permission. If you think you should, please contact support via support@propertymeld.com.";

export const ERROR_404 = "Oops, we could not complete your request. Try refreshing the page.";

export const ERROR_NOT_COMPLETE_REQUEST =
  "Oops, we could not complete your request. Please contact support via support@propertymeld.com.";
