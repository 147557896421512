import { EuiSelectableOption } from "@elastic/eui";
import { useHistory, useLocation } from "react-router-dom";

import { BaseURLFilter, DeepPartial, FilterClassTypes } from "../BaseFilterClasses";
import { BaseSortFilterClass } from "../PmSortFilter/BaseSortFilterClass";

export interface PmSavedFiltersFilterProps {
  options: Array<{ label: string; queryParamValue: string; isGroupLabel: boolean }>;
  popoverWidth: string;
  alwaysShow?: boolean;
  // we need the other filters to clear out their query keys and values
  // when applying a saved filter
  otherFilters: Array<FilterClassTypes | BaseSortFilterClass>;
  onApplyAdditionalOnClick?: (params: URLSearchParams) => void;
}

export interface PmSavedFiltersFilterValue {
  filterType: typeof BaseSavedFiltersFilterClass.type;
  options: EuiSelectableOption[];
}

export class BaseSavedFiltersFilterClass extends BaseURLFilter<
  "",
  null,
  PmSavedFiltersFilterProps & {
    allowedOperators: [];
    componentProps: null;
    queryParamKeyPrefix: "saved_filter";
    filterName: "saved_filters";
    text: "Saved Filters";
  }
> {
  static type = "saved_filters_filter" as const;

  constructor({
    config,
    overrides,
    onApplyAdditionalOnClick,
  }: {
    config: PmSavedFiltersFilterProps;
    overrides?: DeepPartial<PmSavedFiltersFilterProps>;
    onApplyAdditionalOnClick?: (params: URLSearchParams) => void;
  }) {
    super({
      config: {
        ...config,
        allowedOperators: [],
        componentProps: null,
        queryParamKeyPrefix: "saved_filter",
        filterName: "saved_filters",
        text: "Saved Filters",
      },
      overrides,
      onApplyAdditionalOnClick,
    });
  }

  deleteAllParamValues(
    props:
      | {
          location: ReturnType<typeof useLocation>;
          paramsToMutate?: never;
        }
      | {
          location?: never;
          paramsToMutate: URLSearchParams;
        }
  ): URLSearchParams {
    const searchParams = props.paramsToMutate || new URLSearchParams(props.location.search);
    const key = this.getFullQueryParamKey();
    searchParams.delete(key);
    return searchParams;
  }

  getCurrentlyAppliedValue({ location }: { location: ReturnType<typeof useLocation> }): PmSavedFiltersFilterValue {
    const currentSearchParams = new URLSearchParams(location.search);
    const appliedFilterId = currentSearchParams.get("saved_filter");
    const options = this.getDefaultMappedOptions(appliedFilterId);
    return {
      filterType: BaseSavedFiltersFilterClass.type,
      options,
    };
  }

  getDefaultMappedOptions(savedFilterId: string | null): EuiSelectableOption[] {
    return this.getOptions().map((opt) => {
      if (opt.isGroupLabel) {
        return {
          label: opt.label,
          isGroupLabel: true,
        };
      } else {
        return {
          label: opt.label,
          checked: savedFilterId === opt.queryParamValue ? "on" : undefined,
        };
      }
    });
  }

  applyNewFilter({
    location,
    history,
    selectedOptions,
    closePopover,
  }: {
    location: ReturnType<typeof useLocation>;
    history: ReturnType<typeof useHistory>;
    selectedOptions: EuiSelectableOption[];
    closePopover: () => void;
  }) {
    const searchParams = new URLSearchParams(location.search);
    const key = this.getFullQueryParamKey();

    // we need to remove all query param keys for other filters
    // (but preserve any other that may exist in the url)
    for (const filter of this.getOtherFilters()) {
      filter.deleteAllParamValues({ paramsToMutate: searchParams });
    }

    const value = selectedOptions
      .filter((mappedOption) => mappedOption.checked === "on")
      .map((mappedOption) => this.getOptions().find((opt) => opt.label === mappedOption.label)?.queryParamValue)
      .filter(Boolean)[0];

    if (value) {
      searchParams.set(key, value);
      this.getOnApplyAdditionalOnClick()?.(searchParams);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
    closePopover();
  }

  getAppliedFilterCount({ currentValue }: { currentValue: PmSavedFiltersFilterValue }): number {
    return currentValue.options.filter((opt) => opt.checked === "on").length;
  }

  getQueryParamValuesFromURL(
    props: {
      target: "url" | "savedFilter";
      paramsToMutate?: URLSearchParams;
    } & (
      | {
          location: ReturnType<typeof useLocation>;
          existingParams?: never;
        }
      | {
          location?: never;
          existingParams: URLSearchParams;
        }
    )
  ): URLSearchParams {
    const currentURLParams = props.existingParams || new URLSearchParams(props.location.search);
    const newParams = props.paramsToMutate || new URLSearchParams();
    const paramKey = this.getFullQueryParamKey();
    const value = currentURLParams.get(paramKey);
    if (value) {
      newParams.set(paramKey, value);
    }
    return newParams;
  }

  isValidParamKey(key: string): boolean {
    return this.getQueryParamKeyPrefix() === key;
  }

  /*
   * The saved filter objects don't have the '[]' suffix that the query params do
   */
  protected getFullQueryParamKey(): string {
    return this.getQueryParamKeyPrefix();
  }

  private getOtherFilters() {
    return this.getConfig().otherFilters;
  }

  private getOptions() {
    return this.getConfig().options;
  }
}
