import { EuiThemeModifications } from "@elastic/eui";
import { colors } from "@pm-frontend/styles";

export const overrides: EuiThemeModifications<object> = {
  colors: {
    LIGHT: {
      primary: colors.brand.meldBlue,
      success: colors.interface.green.default,
      danger: colors.interface.red.default,
      warning: colors.interface.yellow.default,
      body: colors.brand.white,
      lightShade: colors.neutrals.gray200,
      // this isn't working so I added an override to GlobalStyles
      link: colors.brand.meldBlue,
    },
  },
  font: {
    scale: {
      xl: 1.5,
    },
    body: {
      scale: "m",
    },
  },
  border: {
    thin: `1px solid ${colors.neutrals.gray200}`,
    radius: {
      small: "6px",
    },
  },
  breakpoint: {
    calendarOneDaySidebar: 1050,
    calendarOneDayNoSidebar: 880,
    calendarMultiDaySidebar: 1440,
    calendarMultiDayNoSidebar: 1270,
  },
};
