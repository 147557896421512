import { PmBadgeProps } from "@pm-frontend/shared/components/PmBadge";
import URL from "@pm-shared/utils/url";

export const colors = {
  neutrals: {
    gray50: "#FCFCFC",
    gray100: "#F7F9FA",
    gray200: "#EBEFF2",
    gray300: "#DEE2E5",
    gray400: "#CCD3D9",
    gray500: "#AEB6BD",
    gray600: "#6B757D",
    gray700: "#495057",
    gray800: "#343A40",
    gray900: "#0C0D0D",
  } as const,
  brand: {
    meldBlue: "#1175CC",
    lightBlue: "#F8FBFD",
    veryLightBlue: "#E6F2FF",
    darkHover: "#0B4980",
    white: "#FFFFFF",
  } as const,
  interface: {
    red: {
      default: "#B2250F",
      light: "#FDF2F0",
      dark: "#801A0B",
    } as const,
    green: {
      default: "#006B56",
      light: "#F7FCFB",
      dark: "#004D3E",
      statusText: "#16A085",
    } as const,
    yellow: {
      default: "#FFCE70",
      light: "#FDF6E9",
      dark: "#8F681D",
    } as const,
  },
};

export const badgeColors = {
  dark: {
    orange: {
      bg: "#E5A688",
      text: "#592912",
    } as const,
    yellow: {
      bg: "#E5D188",
      text: "#594A12",
    } as const,
    lightGreen: {
      bg: "#D7E588",
      text: "#4F5912",
    } as const,
    mint: {
      bg: "#88E5C9",
      text: "#125944",
    } as const,
    green: {
      bg: "#88E5B5",
      text: "#125934",
    } as const,
    softBlue: {
      bg: "#88D7E5",
      text: "#124E59",
    } as const,
    softPurple: {
      bg: "#A688E5",
      text: "#281259",
    } as const,
    violet: {
      bg: "#A688E5",
      text: "#281259",
    } as const,
    red: {
      bg: "#E58888",
      text: "#591212",
    } as const,
    salmon: {
      bg: "#E588A5",
      text: "#591228",
    } as const,
    pink: {
      bg: "#E388E5",
      text: "#581259",
    } as const,
  } as const,
  light: {
    orange: {
      bg: "#F2D2C3",
      text: "#592912",
    } as const,
    yellow: {
      bg: "#F2E8C3",
      text: "#594A12",
    } as const,
    lightGreen: {
      bg: "#EBF2C3",
      text: "#4F5912",
    } as const,
    mint: {
      bg: "#C3F2E4",
      text: "#125944",
    } as const,
    green: {
      bg: "#C3F2DA",
      text: "#125934",
    } as const,
    softBlue: {
      bg: "#C3EBF2",
      text: "#124E59",
    } as const,
    softPurple: {
      bg: "#C3D4F2",
      text: "#281259",
    } as const,
    violet: {
      bg: "#D2C3F2",
      text: "#281259",
    } as const,
    red: {
      bg: "#F2C3C3",
      text: "#591212",
    } as const,
    salmon: {
      bg: "#F2C3D2",
      text: "#591228",
    } as const,
    pink: {
      bg: "#F1C3F2",
      text: "#581259",
    } as const,
    gray: {
      bg: "#EBEFF2",
      text: "#495057",
    } as const,
  } as const,
} as const;

type GenericStatusTypes = "approved" | "pending" | "rejected" | "neutral" | "purple";

export const GenericStatusBadgeProps: Record<GenericStatusTypes, Pick<PmBadgeProps, "bgColor" | "textColor">> = {
  approved: {
    bgColor: badgeColors.light.green.bg,
    textColor: badgeColors.light.green.text,
  },
  pending: {
    bgColor: colors.interface.yellow.light,
    textColor: colors.interface.yellow.dark,
  },
  rejected: {
    bgColor: badgeColors.light.red.bg,
    textColor: badgeColors.light.red.text,
  },
  neutral: {
    bgColor: colors.neutrals.gray300,
    textColor: colors.neutrals.gray800,
  },
  purple: {
    bgColor: badgeColors.light.softPurple.bg,
    textColor: badgeColors.light.softPurple.text,
  },
} as const;

// expressed as `vw` to accomodate high-res screens
export const PageContentMaxWidth = "90vw";

// the 64 px is the size of the header.
export const PageContentHeight = `calc(100vh - 64px)`;

// the 96 px is the size of the header + content padding
export const InnerPageContentHeight = `calc(100vh - 96px)`;

// the 80 px is the size of the header + content padding
export const MobileInnerPageContentHeight = `calc(100vh - 80px)`;

// gap between sections on detail pages
export const DetailPageSectionGap = "48px";

// border colors across our UI
export const BorderColor = colors.neutrals.gray400;

export const BorderRadius = "6px";

const ShortIconMap = {
  property: "icons/apartment.svg",
} as const;

// to help standardize the icons used across different pages
export const IconMap = {
  full: {
    property: URL.getStatic(ShortIconMap.property),
  },
  short: ShortIconMap,
} as const;

export const fontSizes = {
  p1: "16px",
  p2: "14px",
  p3: "12px",
} as const;

export const fontWeights = {
  regular: 400,
  semiBold: 600,
  bold: 700,
} as const;
