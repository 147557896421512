import { LinkHelper } from "@pm-shared/utils/link";
import { RouteUrls } from "./route-urls";

interface GetFullAddress {
  property_name?: string;
  line_1?: string;
  line_2?: string;
  line_3?: string;
  city?: string;
  county_province?: string;
  postcode?: string;
}

export function getFullAddressWithPropertyNameArray(prop: GetFullAddress): string[] {
  return [
    getPropertyNameDistinctFromLine1(prop),
    prop.line_1,
    prop.line_2,
    prop.line_3,
    getFormattedCityStateZip(prop),
  ].filter(Boolean) as string[];
}

export function getFullAddressWithoutPropertyName(prop: GetFullAddress): string {
  return [prop.line_1, prop.line_2, prop.line_3, getFormattedCityStateZip(prop)].filter(Boolean).join(", ");
}

export function getPropertyNameDistinctFromLine1(prop: { property_name?: string; line_1?: string }): string {
  if (prop.property_name === prop.line_1) {
    return "";
  }
  return prop.property_name || "";
}

export function getTwoLinePropertyNameAddress(prop: GetFullAddress): string {
  return [getPropertyNameDistinctFromLine1(prop), getFullAddressWithoutPropertyName(prop)].filter(Boolean).join("\n");
}

export const getOneLinePropertyNameAddress = (prop: GetFullAddress, includePropertyName = true): string => {
  const base = [prop.line_1, prop.city, prop.county_province].filter(Boolean).join(", ");
  if (includePropertyName) {
    const propertyName = getPropertyNameDistinctFromLine1(prop);
    if (propertyName) {
      return propertyName + ", " + base;
    }
  }
  return base;
};

export const getPropertyNameLine1 = (prop: GetFullAddress): string => {
  return [getPropertyNameDistinctFromLine1(prop), prop.line_1].filter(Boolean).join(", ");
};

interface GetTwoOrThreeLinePropertyNameAddressProps {
  line_1?: string;
  line_2?: string;
  line_3?: string;
  property_name?: string;
  city?: string;
  county_province?: string;
  postcode?: string;
}
/**
 * you probably need to add `className="preWhiteSpace"` to the wrapping element of this string
 *
 * Proprety Name (if it should be shown)
 * 123 Street Lane
 * Portland, OR 77777
 */
export function getTwoOrThreeLinePropertyNameAddress(prop: GetTwoOrThreeLinePropertyNameAddressProps): string {
  const addressLine = [prop.line_1, prop.line_2, prop.line_3].filter(Boolean).join(", ");
  return [getPropertyNameDistinctFromLine1(prop), addressLine, getFormattedCityStateZip(prop)]
    .filter(Boolean)
    .join("\n");
}

export function getFormattedCityStateZip(prop: { city?: string; county_province?: string; postcode?: string }): string {
  if (prop.city) {
    return `${prop.city}, ${prop.county_province} ${prop.postcode}`;
  }

  return `${prop.county_province} ${prop.postcode}`;
}

export const getPropertyDetailsLink = (propertyId: number, tab?: "edit"): string => {
  if (tab === "edit") {
    return LinkHelper.normalize(RouteUrls.propertyDetailEdit({ id: propertyId }));
  }

  return LinkHelper.normalize(RouteUrls.propertyDetail({ id: propertyId }));
};
