import { create } from "zustand";

import { SchedulableMeldDetailViewSerializer } from "@pm-frontend/shared/types/api/meld/serializers/scheduable_meld_detail_view_serializer";
import { colors } from "@pm-frontend/styles";
import { Moment } from "moment";
import { GetAssignedMaintenanceReturn } from "@pm-frontend/shared/utils/assignment-utils";
import { CalendarPaneState } from "./hooks";
import { AbbreviatedAgentListView } from "@pm-frontend/shared/types/api/manager/serializers/abbreviated_agent_list_view";
import { AbbreviatedVendorSerializer } from "@pm-frontend/shared/types/api/vendor/serializers/abbreviated_vendor_serializer";
import { AltEventSelectedAgentsTimes } from "../calendarStateStore";

export const currentlyAssignedBorder = `2px solid ${colors.brand.darkHover}`;

const doesSegmentMatchAppt = (
  segment: { event: { dtstart: string; dtend?: string | null } },
  appointment: {
    availability_segment: {
      event: {
        dtstart: string;
        dtend?: string | null | undefined;
      };
    } | null;
  }
) => {
  if (
    segment.event.dtstart &&
    segment.event.dtend &&
    appointment.availability_segment?.event.dtstart &&
    appointment.availability_segment.event.dtend
  ) {
    return (
      segment.event.dtstart === appointment.availability_segment.event.dtstart &&
      segment.event.dtend === appointment.availability_segment.event.dtend
    );
  }
  return false;
};

// we consider a segment scheduled if an appointment has a segment with the same times
export const isSegmentScheduled = (
  segment: { event: { dtstart: string; dtend?: string | null } },
  meld: SchedulableMeldDetailViewSerializer
): boolean => {
  if (meld.managementappointment.length > 0) {
    return meld.managementappointment.some((appt) => doesSegmentMatchAppt(segment, appt));
  } else if (meld.vendorappointment.length > 0) {
    return meld.vendorappointment.some((appt) => doesSegmentMatchAppt(segment, appt));
  }
  return false;
};

const CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY = `ui-calendar-redesign-enabled-v2-${
  window.PM.user?.id || "karma-test-fix"
}`;

// we want this to default to true for the first read
const readCalendarStateFromLocalStorage = (): boolean => {
  try {
    const rawKey = window.localStorage.getItem(CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY);
    if (rawKey === null) {
      writeCalendarStateToLocalStorage(true);
      return true;
    } else {
      return !!JSON.parse(rawKey);
    }
  } catch {
    return false;
  }
};

const writeCalendarStateToLocalStorage = (value: boolean) => {
  try {
    window.localStorage.setItem(CALENDAR_REDESIGN_TOGGLE_SESSION_STORAGE_KEY, JSON.stringify(value));
    return;
  } catch {
    return;
  }
};

interface CalendarRedesignToggleState {
  enabled: boolean;
  actions: {
    toggleCalendarRedesignEnabled: () => void;
  };
}

export const useCalendarRedesignToggleStore = create<CalendarRedesignToggleState>((set) => ({
  enabled: readCalendarStateFromLocalStorage(),
  actions: {
    toggleCalendarRedesignEnabled: () => {
      set((state) => {
        writeCalendarStateToLocalStorage(!state.enabled);
        // give a little wiggle room for the segment event tracking to fire
        setTimeout(() => location.reload(), 200);
        return { enabled: !state.enabled };
      });
    },
  },
}));

interface IsEventAvailabilityProps {
  managementavailabilitysegment?: {
    scheduled_management_appointment: number | null | undefined;
  };
  vendoravailabilitysegment?: {
    scheduled_vendor_appointment: number | null | undefined;
  };
}

/**
 * The events list endpoints returns all scheduled events and availabilities
 * this function can be used to filter
 */
export const isEventAvailability = (event: IsEventAvailabilityProps): boolean => {
  return !(
    typeof event.managementavailabilitysegment?.scheduled_management_appointment === "number" ||
    typeof event.vendoravailabilitysegment?.scheduled_vendor_appointment === "number"
  );
};

export const getCellBackgroundColor = (isInPast: boolean): string => {
  if (isInPast) {
    return colors.neutrals.gray200;
  } else {
    return colors.brand.white;
  }
};

export const getScheduleFormMinStartTime = (
  event: {
    date: Moment;
    startTime: Moment | undefined;
    endTime: Moment | undefined;
  },
  now: Moment
) => {
  // events in the past have all options disabled
  // this can probably be removed if we move past events out of the form
  if (event.date.isBefore(now, "day")) {
    return event.date.clone().endOf("day");
  } else if (event.date.isSame(now, "day")) {
    const result = event.date.clone();
    result.hours(now.hours());
    result.minutes(now.minutes());
    result.seconds(0);
    // on the day of we use the current time
    return result;
  } else {
    // in the future we allow all times
    return event.date.clone().startOf("day");
  }
};

export const getScheduleFormMaxEndTime = (
  event: {
    date: Moment;
    startTime: Moment | undefined;
    endTime: Moment | undefined;
  },
  now: Moment
) => {
  // events in the past have all options disabled
  // this can probably be removed if we move past events out of the form
  if (event.date.isBefore(now, "day")) {
    return event.date.clone().endOf("day");
  }
  // we use the current time plus 8 hours
  // (or end of the day if that would take us into tomorrow)
  if (event.startTime) {
    const result = event.startTime.clone().add(8, "hours");

    if (result.isSame(event.date, "day")) {
      return result;
    }
  }
  return event.date.clone().endOf("day");
};

export const getScheduleFormMinEndTime = (
  event: {
    date: Moment;
    startTime: Moment | undefined;
    endTime: Moment | undefined;
  },
  now: Moment
) => {
  // events in the past have all options disabled
  // this can probably be removed if we move past events out of the form
  if (event.date.isBefore(now, "day")) {
    return event.date.clone().endOf("day");
  }
  if (event.startTime) {
    return event.startTime.clone();
  }
  return event.date.clone().startOf("day");
};

export const getCurrentlyActiveAgentsVendors = (
  assignees: GetAssignedMaintenanceReturn<{ id: number }, { id: number }, { id: number }>,
  rightPaneState: CalendarPaneState,
  selectedAgents: AbbreviatedAgentListView,
  selectedVendors: AbbreviatedVendorSerializer[],
  altEventSelectedAgentsTimes: AltEventSelectedAgentsTimes | null
): { agents: Array<{ id: number }>; vendors: Array<{ id: number }> } => {
  if (assignees && rightPaneState.type === "meldDetails") {
    if (assignees.type === "ManagementAgent") {
      return {
        agents: selectedAgents.filter((agent) => assignees.in_house_servicers.some((a) => a.id === agent.id)),
        vendors: [],
      };
    } else if (assignees.type === "Vendor") {
      if (selectedVendors.some((v) => v.id === assignees.vendor.id)) {
        return { vendors: selectedVendors.filter((v) => v.id === assignees.vendor.id), agents: [] };
      }
    }
  } else if (assignees && rightPaneState.type === "offerAvailabilities") {
    if (assignees.type === "ManagementAgent") {
      return {
        agents: selectedAgents.filter((agent) => assignees.in_house_servicers.some((a) => a.id === agent.id)),
        vendors: [],
      };
    }
  } else if (altEventSelectedAgentsTimes && rightPaneState.type === "alternativeEvent") {
    return {
      agents: selectedAgents.filter((agent) =>
        altEventSelectedAgentsTimes.selectedAgents.some((agentId) => agentId === agent.id)
      ),
      vendors: [],
    };
  }
  return { agents: [], vendors: [] };
};
