import { FilterClassTypes } from "./BaseFilterClasses";
import { BaseSavedFiltersFilterClass } from "./PmSavedFiltersFilter/BaseSavedFiltersFilterClass";
import { BaseSortFilterClass } from "./PmSortFilter/BaseSortFilterClass";

/**
Given the existing URLSearchParams and an array of filters, return
URLSearchParams of all existing search param entries that match filters
*/
export const getParamsMatchingFilters = ({
  existingParams,
  paramsToMutate,
  filters,
  savedFilter,
  sortFilter,
}: {
  existingParams: URLSearchParams;
  paramsToMutate?: URLSearchParams;
  filters?: FilterClassTypes[];
  savedFilter?: BaseSavedFiltersFilterClass;
  sortFilter?: BaseSortFilterClass;
}): [URLSearchParams, boolean] => {
  const result = paramsToMutate || new URLSearchParams();
  let foundMatch = false;

  // @ts-expect-error TS doesn't handle .entries()
  for (const [key, value] of existingParams.entries()) {
    if (filters) {
      // if the param matches a filter we keep it in existingMeldFilterParams
      for (const filter of filters) {
        if (filter.isValidParamKey(key)) {
          result.set(key, value);
          foundMatch = true;
        }
      }
    }
    // and check for saved_filter as well
    if (savedFilter?.isValidParamKey(key)) {
      result.set(key, value);
      foundMatch = true;
    }
    // and check for saved_filter as well
    if (sortFilter?.isValidParamKey(key)) {
      result.set(key, value);
      foundMatch = true;
    }
  }
  return [result, foundMatch];
};
