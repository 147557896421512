var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
function deepMerge(target, source) {
  const output = __spreadValues({}, target);
  for (const key in source) {
    if (source[key] === void 0) {
      continue;
    }
    if (isObject(output[key]) && isObject(source[key])) {
      output[key] = deepMerge(output[key], source[key]);
    } else {
      output[key] = source[key];
    }
  }
  return output;
}
function isObject(item) {
  return item !== null && typeof item === "object" && !Array.isArray(item);
}
const _BaseURLFilter = class {
  /**
          Given an array of filters, delete all associated filter [key,value]s
          */
  static deleteAllFilterValues(props) {
    const result = props.paramsToMutate || new URLSearchParams(props.location.search);
    if (props.filters) {
      for (const filter of props.filters) {
        filter.deleteAllParamValues({ paramsToMutate: result });
      }
    }
    if (props.savedFilterClass) {
      props.savedFilterClass.deleteAllParamValues({ paramsToMutate: result });
    }
    if (props.sortFilter) {
      props.sortFilter.deleteAllParamValues({ paramsToMutate: result });
    }
    return result;
  }
  /**
          Given an array of filters, get the currently applied params
          */
  static getFilterValues(props) {
    const currentParams = props.existingParams || new URLSearchParams(location.search);
    const result = props.paramsToMutate || new URLSearchParams();
    if (props.filters) {
      for (const filter of props.filters) {
        if (props.savedFilter) {
          filter.getQueryParamsFromSavedFilter({ savedFilter: props.savedFilter, paramsToMutate: result });
        } else {
          filter.getQueryParamValuesFromURL({ existingParams: currentParams, target: "url", paramsToMutate: result });
        }
      }
    }
    if (props.savedFilterClass) {
      if (props.savedFilter) {
        props.savedFilterClass.getQueryParamsFromSavedFilter({
          savedFilter: props.savedFilter,
          paramsToMutate: result
        });
      } else {
        props.savedFilterClass.getQueryParamValuesFromURL({
          existingParams: currentParams,
          target: "url",
          paramsToMutate: result
        });
      }
    }
    if (props.sortFilter) {
      if (props.savedFilter) {
        props.sortFilter.getQueryParamsFromSavedFilter({ savedFilter: props.savedFilter, paramsToMutate: result });
      } else {
        props.sortFilter.getQueryParamValuesFromURL({
          existingParams: currentParams,
          target: "url",
          paramsToMutate: result
        });
      }
    }
    return result;
  }
  constructor({
    config,
    overrides,
    onApplyAdditionalOnClick
  }) {
    const _config = overrides ? deepMerge(config, overrides) : config;
    this.config = _config;
    this.onApplyAdditionalOnClick = onApplyAdditionalOnClick;
  }
  /***
   * Operators such as 'All of', 'None of', 'Between', 'Missing' etc
   */
  getAllowedOperatorOptions() {
    return this.getAllowedOperators().map((opt) => {
      return {
        label: opt,
        value: opt,
        dropdownDisplay: opt,
        inputDisplay: opt
      };
    });
  }
  getButtonText() {
    return this.getConfig().text;
  }
  getFilterName() {
    return this.getConfig().filterName;
  }
  getOnClearClick({
    location: location2,
    closePopover,
    history,
    savedFilter
  }) {
    return () => {
      var _a;
      const savedSearchParams = this.getQueryParamsFromSavedFilter({ savedFilter, location: location2 });
      this.deleteAllParamValues({ paramsToMutate: savedSearchParams });
      (_a = this.getOnApplyAdditionalOnClick()) == null ? void 0 : _a(savedSearchParams);
      history.replace({
        pathname: location2.pathname,
        search: savedSearchParams.toString()
      });
      closePopover();
    };
  }
  getPopoverWidth() {
    return "350px";
  }
  getQueryParamsFromSavedFilter({
    savedFilter,
    location: location2,
    paramsToMutate
  }) {
    if (savedFilter === void 0) {
      const newParams = paramsToMutate || new URLSearchParams(location2.search);
      newParams.delete("saved_filter");
      return newParams;
    }
    const searchParams = paramsToMutate || new URLSearchParams();
    for (const entry of Object.entries(savedFilter)) {
      const [key, value] = entry;
      if (_BaseURLFilter.SAVED_FILTER_IGNORED_FIELDS.includes(key)) {
        continue;
      }
      if (value === "" || value === null || Array.isArray(value) && value.length === 0) {
        continue;
      }
      if (Array.isArray(value)) {
        searchParams.set(key + "[]", value.join(","));
      } else {
        searchParams.append(key, value);
      }
    }
    return searchParams;
  }
  getQueryParamValuesFromURL(props) {
    const currentURLParams = props.existingParams || new URLSearchParams(props.location.search);
    const newParams = props.paramsToMutate || new URLSearchParams();
    for (const operator of this.getAllowedOperators()) {
      const paramKey = this.getFullQueryParamKey({ operator, target: props.target });
      if (!paramKey) {
        continue;
      }
      const value = currentURLParams.get(paramKey);
      if (value) {
        newParams.set(paramKey, value);
      }
    }
    return newParams;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAppliedFilterCount(_props) {
    throw new Error("This method must be implemented by sub-classes");
  }
  getShouldAlwaysShow() {
    return !!this.getConfig().alwaysShow;
  }
  deleteAllParamValues(props) {
    const searchParams = props.paramsToMutate || new URLSearchParams(props.location.search);
    for (const operator of this.getAllowedOperators()) {
      const key = this.getFullQueryParamKey({ operator, target: "url" });
      if (key) {
        searchParams.delete(key);
      }
    }
    return searchParams;
  }
  isValidParamKey(key) {
    for (const operator of this.getAllowedOperators()) {
      if (this.getFullQueryParamKey({ operator, target: "url" }) === key) {
        return true;
      }
    }
    return false;
  }
  getAllowedOperators() {
    return this.getConfig().allowedOperators;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFullQueryParamKey(_props) {
    throw new Error("This method must be implemented by sub-classes");
  }
  getQueryParamKeyPrefix() {
    return this.getConfig().queryParamKeyPrefix;
  }
  getConfig() {
    return this.config;
  }
  getOnApplyAdditionalOnClick() {
    return this.onApplyAdditionalOnClick;
  }
  getSavedFilterValue({
    savedFilter,
    operator
  }) {
    const key = this.getFullQueryParamKey({ operator, target: "savedFilter" });
    return key ? savedFilter[key] : void 0;
  }
  getSearchParamValue({
    operator,
    location: location2,
    currentSearchParams
  }) {
    const searchParams = currentSearchParams || new URLSearchParams(location2.search);
    const key = this.getFullQueryParamKey({ operator, target: "url" });
    return key ? searchParams.get(key) : void 0;
  }
};
export let BaseURLFilter = _BaseURLFilter;
BaseURLFilter.SAVED_FILTER_IGNORED_FIELDS = [
  "id",
  "name",
  "filterset_fields",
  "position",
  "private",
  "current_agent_default",
  "limit",
  "offset"
];
