import { EuiSelectableOption } from "@elastic/eui";
import { useHistory, useLocation } from "react-router-dom";

import { BaseURLFilter, DeepPartial, SavedFilterObject } from "../BaseFilterClasses";

export interface PmSortFilterProps {
  filterName: string;
  options: Array<{ label: string; queryParamValue: string }>;
  // the option that is shown as and applied if no sorting is applied by
  // saved filter or url
  defaultOption: string;
  queryParamKeyPrefix: string;
  popoverWidth: string;
  alwaysShow?: boolean;
}

export class BaseSortFilterClass extends BaseURLFilter<
  "pick_one",
  null,
  PmSortFilterProps & {
    componentProps: null;
    allowedOperators: ["pick_one"];
    text: string;
  }
> {
  static type = "sort_filter" as const;

  constructor({
    config,
    overrides,
    onApplyAdditionalOnClick,
  }: {
    config: PmSortFilterProps;
    overrides?: DeepPartial<PmSortFilterProps>;
    onApplyAdditionalOnClick?: (params: URLSearchParams) => void;
  }) {
    super({
      config: { ...config, text: "Sort", componentProps: null, allowedOperators: ["pick_one"] },
      overrides,
      onApplyAdditionalOnClick,
    });
  }

  getAppliedFilterCount({ currentValue }: { currentValue: string | null | undefined }) {
    return currentValue ? 1 : 0;
  }

  getDefaultMappedOptions(): EuiSelectableOption[] {
    return this.getOptions().map((opt) => ({
      label: opt.label,
      checked: undefined,
    }));
  }

  getMappedOptions({
    savedFilter,
    location,
  }: {
    savedFilter: SavedFilterObject;
    location: ReturnType<typeof useLocation>;
  }): EuiSelectableOption[] {
    const value = savedFilter ? this.getSavedFilterValue({ savedFilter }) : this.getSortSearchParamValue({ location });
    const selectedOption = this.getOptions().find((opt) => opt.queryParamValue === value);

    return this.getDefaultMappedOptions().map((mappedOption) => {
      if (selectedOption?.label === mappedOption.label) {
        mappedOption.checked = "on";
      }
      return mappedOption;
    });
  }

  getShouldShowFilter({ currentParamValue }: { currentParamValue: string | null }): boolean {
    if (this.getShouldAlwaysShow()) {
      return true;
    }
    return currentParamValue !== null;
  }

  onOptionChange({
    savedFilter,
    location,
    history,
    newOptions,
  }: {
    savedFilter: SavedFilterObject;
    location: ReturnType<typeof useLocation>;
    history: ReturnType<typeof useHistory>;
    newOptions: Array<EuiSelectableOption<string>>;
  }) {
    const searchParams = this.getQueryParamsFromSavedFilter({ savedFilter, location });
    const selectedMappedOption = newOptions.find((opt) => opt.checked === "on");
    const key = this.getFullQueryParamKey();
    if (!selectedMappedOption) {
      searchParams.delete(key);
    } else {
      const foundOption = this.getOptions().find((opt) => opt.label === selectedMappedOption.label);
      if (!foundOption) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, foundOption.queryParamValue);
      }
    }

    this.getOnApplyAdditionalOnClick()?.(searchParams);
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }

  getSortSearchParamValue({ location }: { location: ReturnType<typeof useLocation> }) {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(this.getFullQueryParamKey());
  }

  isValidParamKey(key: string): boolean {
    return this.getFullQueryParamKey() === key;
  }

  /*
   * The saved filter objects don't have the '[]' suffix that the query params do
   */
  protected getFullQueryParamKey(): string {
    return this.getQueryParamKeyPrefix();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getSavedFilterValue({ savedFilter }: { savedFilter: NonNullable<SavedFilterObject> }): any[] | any {
    return savedFilter[this.getFullQueryParamKey()];
  }

  private getOptions() {
    return this.getConfig().options;
  }
}
