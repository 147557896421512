var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIconTip, EuiLink, EuiText, EuiTextColor } from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
import { Link } from "react-router-dom";
import Linkify from "react-linkify";
import URL from "@pm-shared/utils/url";
const seeAllLink = (onClick, dataTestId) => {
  const onClickObj = typeof onClick === "function" ? { onClick } : {};
  return /* @__PURE__ */ React.createElement(
    EuiLink,
    __spreadProps(__spreadValues({}, onClickObj), {
      color: "primary",
      style: { fontWeight: 700, width: "max-content" },
      "data-testid": dataTestId ? dataTestId["data-testid"] + "-see-all-link" : void 0
    }),
    "See All"
  );
};
const getSingleTitleAction = (action) => {
  if ("onClick" in action) {
    return /* @__PURE__ */ React.createElement(EuiLink, { onClick: action.onClick, "data-testid": action.dataTestId }, /* @__PURE__ */ React.createElement(EuiText, { size: "s" }, action.text));
  } else if ("href" in action) {
    return /* @__PURE__ */ React.createElement(Link, { to: action.href }, /* @__PURE__ */ React.createElement(EuiLink, { "data-testid": action.dataTestId }, /* @__PURE__ */ React.createElement(EuiText, { size: "s" }, action.text)));
  }
};
const transformListItemTitle = (item) => {
  let titleText;
  if (typeof item.title === "string") {
    titleText = /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
      EuiText,
      {
        size: "s",
        color: colors.neutrals.gray800,
        style: { fontWeight: 700 },
        "data-testid": item.titleDataTestId
      },
      item.title
    ));
  } else {
    titleText = item.title;
  }
  let titleActions;
  if (Array.isArray(item.titleAction)) {
    titleActions = /* @__PURE__ */ React.createElement("span", null, item.titleAction.filter((action) => !action.hasOwnProperty("enabled") || action.enabled).map((action, index, arr) => /* @__PURE__ */ React.createElement(React.Fragment, { key: action.text }, getSingleTitleAction(action), index < arr.length - 1 && /* @__PURE__ */ React.createElement("span", { style: { margin: "0 4px" } }, " \u2022 "))));
  } else if (item.titleAction && (!item.titleAction.hasOwnProperty("enabled") || item.titleAction.enabled)) {
    titleActions = getSingleTitleAction(item.titleAction);
  }
  const titleTooltip = item.titleHelpTooltip ? /* @__PURE__ */ React.createElement(
    EuiIconTip,
    {
      type: URL.getStatic("icons/questionmark.svg"),
      color: colors.neutrals.gray800,
      content: item.titleHelpTooltip,
      iconProps: {
        className: "eui-alignTop",
        size: "s"
      }
    }
  ) : null;
  return /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "row",
      alignItems: "center",
      justifyContent: item.titleActionAlignment === "right" ? "spaceBetween" : "flexStart",
      responsive: false,
      style: { gap: "16px" }
    },
    /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        direction: "row",
        alignItems: "center",
        justifyContent: "flexStart",
        responsive: false,
        style: { gap: "6px" }
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, titleText),
      titleTooltip && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, titleTooltip)
    )),
    titleActions && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, titleActions)
  );
};
const transformListItemDescription = (value) => {
  if (typeof value === "string") {
    return /* @__PURE__ */ React.createElement(EuiText, { size: "s", color: colors.neutrals.gray800, className: "preWhiteSpace", style: { wordWrap: "break-word" } }, value);
  } else {
    return value;
  }
};
const transformListItems = (listItems) => {
  return listItems.map((item) => {
    const newTitle = transformListItemTitle(item);
    let descriptionIsArray;
    let newDescription;
    if (!Array.isArray(item.description)) {
      descriptionIsArray = false;
      newDescription = transformListItemDescription(item.description);
    } else {
      descriptionIsArray = true;
      newDescription = item.description.map(transformListItemDescription);
    }
    return {
      title: newTitle,
      description: newDescription,
      descriptionIsArray,
      dataTestId: item.dataTestId,
      descriptionSeeAllOnClick: item.descriptionSeeAllOnClick,
      displayAll: item.displayAll
    };
  });
};
const PmDescriptionList = ({ listItems, gapInPx = 16, "data-testid": listDataTestId }) => {
  const filteredListItems = listItems.filter((item) => !item.hasOwnProperty("enabled") || item.enabled);
  const mapDescriptionToFlexItems = (description, descriptionSeeAllOnClick, dataTestId, displayAll = false) => {
    const dataTestIdProp = dataTestId ? { "data-testid": dataTestId } : {};
    if (!Array.isArray(description)) {
      return /* @__PURE__ */ React.createElement(Linkify, { className: "inline-link" }, /* @__PURE__ */ React.createElement(EuiFlexItem, __spreadValues({ grow: false }, dataTestIdProp), /* @__PURE__ */ React.createElement("dd", null, description)));
    }
    return /* @__PURE__ */ React.createElement("dd", null, /* @__PURE__ */ React.createElement(EuiFlexItem, __spreadValues({ grow: false }, dataTestIdProp), /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "none", alignItems: "flexStart" }, description.map((item, index) => {
      if (index >= 2 && displayAll === false) {
        return null;
      }
      return /* @__PURE__ */ React.createElement(EuiFlexItem, { key: index, grow: false }, item);
    }))), description.length > 2 && displayAll === false ? /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, seeAllLink(descriptionSeeAllOnClick, dataTestIdProp)) : null);
  };
  return /* @__PURE__ */ React.createElement("dl", null, /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "column",
      alignItems: "stretch",
      style: { gap: gapInPx, wordBreak: "break-word" },
      "data-testid": listDataTestId
    },
    transformListItems(filteredListItems).map((listItem, index) => {
      return (
        /* I believe it is safe to use the index as a key here because the order will never change */
        /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true, key: index }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", gutterSize: "xs", alignItems: "stretch", justifyContent: "spaceBetween" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, /* @__PURE__ */ React.createElement(EuiTextColor, { color: colors.neutrals.gray800 }, /* @__PURE__ */ React.createElement("dt", null, listItem.title))), /* @__PURE__ */ React.createElement(EuiTextColor, { color: colors.neutrals.gray800 }, mapDescriptionToFlexItems(
          listItem.description,
          listItem.descriptionSeeAllOnClick,
          listItem.dataTestId,
          listItem.displayAll
        ))))
      );
    })
  ));
};
export { PmDescriptionList };
