var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { MeldManagerStatusLabels, MeldStatus } from "@pm-frontend/shared/types/meld";
import { getCategoryLabel } from "@pm-frontend/shared/utils/meld-utils";
import { WorkCategory } from "@pm-frontend/shared/utils/statuses";
import { WorkType } from "@pm-frontend/shared/utils/statuses";
import { getWorkTypeLabel } from "@pm-frontend/shared/utils/meld-utils";
import { BaseSortFilterClass } from "../PmSortFilter/BaseSortFilterClass";
import { BaseComboboxFilterClass } from "../PmComboboxFilter/BaseComboboxFilterClass";
import { BaseDateFilterClass } from "../PmDateFilter/BaseDateFilterClass";
import { BaseDecimalFilterClass } from "../PmDecimalFilter/BaseDecimalFilterClass";
import { BaseSelectableFilterClass } from "../PmSelectableFilter/BaseSelectableFilterClass";
import { getFullName } from "@pm-frontend/shared/utils/agent-utils";
const MeldFilterQueryKeysPrefix = {
  assignedMaintenance: "maint",
  categories: "categories",
  coordinator: "coordinator",
  creation_date: "created",
  maint_type: "maint_type",
  priority: "priority",
  sort: "ordering",
  status: "status",
  tpr: "tpr",
  work_hours_logged: "wl",
  work_type: "work_type"
};
export const MeldFilterQueryKeys = __spreadProps(__spreadValues({}, MeldFilterQueryKeysPrefix), {
  priority: MeldFilterQueryKeysPrefix.priority + "[]",
  status: MeldFilterQueryKeysPrefix.status + "[]",
  work_type: MeldFilterQueryKeysPrefix.work_type + "[]",
  coordinator: MeldFilterQueryKeysPrefix.coordinator + "[]",
  maint_type: MeldFilterQueryKeysPrefix.maint_type + "[]"
});
export const getMeldSavedFilterConfig = ({
  savedFilters,
  otherFilters
}) => {
  const privateOptions = savedFilters.filter((opt) => opt.private).map((opt) => ({ label: opt.name, queryParamValue: opt.id.toString(), isGroupLabel: false }));
  const sharedOptions = savedFilters.filter((opt) => !opt.private).map((opt) => ({ label: opt.name, queryParamValue: opt.id.toString(), isGroupLabel: false }));
  return {
    options: [
      { label: "Shared", isGroupLabel: true, queryParamValue: "" },
      ...sharedOptions,
      { label: "Private", isGroupLabel: true, queryParamValue: "" },
      ...privateOptions
    ],
    popoverWidth: "300px",
    alwaysShow: true,
    otherFilters
  };
};
export const MeldFilterSortValues = {
  "Created: Old": "created",
  "Created: Recent": "-created",
  "Meld: A-Z": "brief_description",
  "Meld: Z-A": "-brief_description",
  "Scheduled: Soon": "scheduled",
  "Scheduled: Later": "-scheduled",
  "Updated: Old": "updated",
  "Updated: Recent": "-updated"
};
const MeldFilterSortConfigs = {
  sort: {
    filterName: "sort_melds",
    options: [
      { label: "Created: Old", queryParamValue: MeldFilterSortValues["Created: Old"] },
      { label: "Created: Recent", queryParamValue: MeldFilterSortValues["Created: Recent"] },
      { label: "Meld: A-Z", queryParamValue: MeldFilterSortValues["Meld: A-Z"] },
      { label: "Meld: Z-A", queryParamValue: MeldFilterSortValues["Meld: Z-A"] },
      { label: "Scheduled: Soon", queryParamValue: MeldFilterSortValues["Scheduled: Soon"] },
      { label: "Scheduled: Later", queryParamValue: MeldFilterSortValues["Scheduled: Later"] },
      { label: "Updated: Old", queryParamValue: MeldFilterSortValues["Updated: Old"] },
      { label: "Updated: Recent", queryParamValue: MeldFilterSortValues["Updated: Recent"] }
    ],
    defaultOption: "Created: Old",
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.sort,
    alwaysShow: true,
    popoverWidth: "300px"
  }
};
const MeldSelectableFilterConfigs = {
  priority: {
    text: "Priority",
    filterName: "priority",
    options: [
      { label: "High", queryParamValue: "HIGH" },
      { label: "Medium", queryParamValue: "MEDIUM" },
      { label: "Low", queryParamValue: "LOW" }
    ],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.priority,
    componentProps: {
      searchable: false
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of", "None of"]
  },
  maint_type: {
    text: "Maintenance Type",
    filterName: "maintenance_type",
    options: [
      { label: "External", queryParamValue: "2" },
      { label: "Internal", queryParamValue: "1" }
    ],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.maint_type,
    componentProps: {
      searchable: false,
      singleSelection: true
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of"]
  },
  status: {
    text: "Status",
    filterName: "status",
    options: Object.keys(MeldStatus).map((status) => ({
      label: MeldManagerStatusLabels[status],
      queryParamValue: status
      // this is an unused status
    })).filter((opt) => opt.queryParamValue !== MeldStatus.OPEN),
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.status,
    componentProps: {
      searchable: true,
      singleSelection: false
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of"]
  },
  tpr: {
    text: "Resident Required",
    popoverWidth: "300px",
    filterName: "tpr",
    options: [
      { label: "Required", queryParamValue: "true" },
      { label: "Not Required", queryParamValue: "false" }
    ],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.tpr,
    allowedOperators: ["Any of"],
    componentProps: {
      searchable: false,
      singleSelection: true
    }
  },
  work_type: {
    text: "Work Type",
    filterName: "work_type",
    options: Object.keys(WorkType).map((workType) => ({
      label: getWorkTypeLabel(workType),
      queryParamValue: workType
    })),
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.work_type,
    componentProps: {
      searchable: true,
      singleSelection: false
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of", "None of"]
  }
};
const MeldComboboxFilterConfigs = {
  assignedMaintenance: {
    text: "Assigned Maintenance",
    popoverWidth: "300px",
    filterName: "meld_assigned_maint",
    // this property must be overriden when constructing the BaseComboboxFilterClass
    options: [],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.assignedMaintenance,
    allowedOperators: ["Any of"],
    componentProps: {
      placeholder: "Filter by assigned maintenance"
    }
  },
  categories: {
    text: "Categories",
    popoverWidth: "300px",
    filterName: "meld_category",
    options: Object.values(WorkCategory).map((wc) => ({
      label: getCategoryLabel(wc),
      queryParamValue: wc,
      created: void 0
    })),
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.categories,
    allowedOperators: ["Any of"],
    componentProps: {
      placeholder: "Filter by work category"
    }
  },
  coordinator: {
    filterName: "coordinator",
    text: "Coordinator",
    // this property must be overriden when constructing the BaseComboboxFilterClass
    options: [],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.coordinator,
    popoverWidth: "300px",
    componentProps: {
      placeholder: "Filter by coordinator"
    },
    allowedOperators: ["Any of", "None of", "Missing", "Present"]
  }
};
const MeldFilterConfigsDate = {
  creation_date: {
    text: "Creation Date",
    filterName: "creation_date",
    componentProps: null,
    alwaysShow: false,
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.creation_date,
    allowedOperators: ["In range", "Not in range", "In the past", "Older than", "Equal to", "Before", "After"],
    popoverWidth: "300px",
    suffixOverrides: { _gte: "__gte", _lte: "__lte", _offset_lte: "__offset_lte", _interval: "__interval" }
  }
};
const MeldDecimalFilterConfigs = {
  work_hours_logged: {
    text: "Hours logged",
    filterName: "work_hours_logged",
    numberUnitLabel: "Hours",
    popoverWidth: "300px",
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.work_hours_logged,
    allowedOperators: [
      "Greater or equal to",
      "Lesser or equal to",
      "Between",
      "Missing",
      "Present",
      "Equal to",
      "Not equal to"
    ],
    suffixOverrides: { _gte: "__gte", _lte: "__lte" },
    componentProps: null,
    alwaysShow: false
  }
};
export const MeldFilterConfigs = {
  selectable: MeldSelectableFilterConfigs,
  combobox: MeldComboboxFilterConfigs,
  decimal: MeldDecimalFilterConfigs,
  date: MeldFilterConfigsDate,
  sort: MeldFilterSortConfigs
};
export function getDefaultMeldFilters({
  allMaint,
  overrides,
  onApplyAdditionalOnClick
}) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k;
  const allAgents = (allMaint || []).filter(
    (maint) => maint.type === "ManagementAgent"
  );
  const allVendors = (allMaint || []).filter((maint) => maint.type === "Vendor");
  const allVendorInvites = (allMaint || []).filter(
    (maint) => maint.type === "VendorInvite"
  );
  const allAgentsMapped = allAgents.map((agent) => ({
    queryParamValue: agent.id.toString(),
    label: getFullName(agent),
    created: agent.created
  }));
  const allMaintMapped = [
    { label: "Agents", isGroupLabelOption: true, queryParamValue: "", created: void 0 },
    ...allAgents.map((agent) => ({
      queryParamValue: agent.composite_id,
      label: `${getFullName(agent)} (Maintenance)`,
      created: agent.created
    })),
    { label: "Vendors", isGroupLabelOption: true, queryParamValue: "", created: void 0 },
    ...allVendors.map((vendor) => ({
      queryParamValue: vendor.composite_id,
      created: vendor.created,
      label: `${vendor.name || vendor.email || ""} (Vendor)`
    })),
    { label: "Vendor Invites", isGroupLabelOption: true, queryParamValue: "", created: void 0 },
    ...allVendorInvites.map((invite) => ({
      queryParamValue: invite.composite_id,
      created: invite.created,
      label: `${invite.name || invite.email || ""} (Invited Vendor)`
    }))
  ];
  return {
    filters: [
      // NOTE: these are alphabetical by the filter button text
      new BaseComboboxFilterClass({
        config: MeldFilterConfigs.combobox.assignedMaintenance,
        overrides: __spreadValues({ options: allMaintMapped }, ((_a = overrides == null ? void 0 : overrides.combobox) == null ? void 0 : _a.assignedMaintenance) || {}),
        onApplyAdditionalOnClick
      }),
      new BaseComboboxFilterClass({
        config: MeldFilterConfigs.combobox.categories,
        overrides: (_b = overrides == null ? void 0 : overrides.combobox) == null ? void 0 : _b.categories,
        onApplyAdditionalOnClick
      }),
      new BaseComboboxFilterClass({
        config: MeldFilterConfigs.combobox.coordinator,
        overrides: __spreadValues({ options: allAgentsMapped }, ((_c = overrides == null ? void 0 : overrides.combobox) == null ? void 0 : _c.coordinator) || {}),
        onApplyAdditionalOnClick
      }),
      new BaseDateFilterClass({
        config: MeldFilterConfigs.date.creation_date,
        overrides: (_d = overrides == null ? void 0 : overrides.date) == null ? void 0 : _d.creation_date,
        onApplyAdditionalOnClick
      }),
      new BaseDecimalFilterClass({
        config: MeldFilterConfigs.decimal.work_hours_logged,
        overrides: (_e = overrides == null ? void 0 : overrides.decimal) == null ? void 0 : _e.work_hours_logged,
        onApplyAdditionalOnClick
      }),
      new BaseSelectableFilterClass({
        config: MeldFilterConfigs.selectable.maint_type,
        overrides: (_f = overrides == null ? void 0 : overrides.selectable) == null ? void 0 : _f.maint_type,
        onApplyAdditionalOnClick
      }),
      new BaseSelectableFilterClass({
        config: MeldFilterConfigs.selectable.priority,
        overrides: (_g = overrides == null ? void 0 : overrides.selectable) == null ? void 0 : _g.priority,
        onApplyAdditionalOnClick
      }),
      new BaseSelectableFilterClass({
        config: MeldFilterConfigs.selectable.status,
        overrides: (_h = overrides == null ? void 0 : overrides.selectable) == null ? void 0 : _h.status,
        onApplyAdditionalOnClick
      }),
      new BaseSelectableFilterClass({
        config: MeldFilterConfigs.selectable.tpr,
        overrides: (_i = overrides == null ? void 0 : overrides.selectable) == null ? void 0 : _i.tpr,
        onApplyAdditionalOnClick
      }),
      new BaseSelectableFilterClass({
        config: MeldFilterConfigs.selectable.work_type,
        overrides: (_j = overrides == null ? void 0 : overrides.selectable) == null ? void 0 : _j.work_type,
        onApplyAdditionalOnClick
      })
      // TODO: scheduled date
    ],
    sortFilter: new BaseSortFilterClass({
      config: MeldFilterConfigs.sort.sort,
      overrides: (_k = overrides == null ? void 0 : overrides.sort) == null ? void 0 : _k.sort,
      onApplyAdditionalOnClick
    })
  };
}
