export const normalizePath = (path: string) => {
  return `/:multitenantId/:orgType/:org/${path}`;
};

const getOrgId = () => {
  return window.location.pathname.split("/")[3];
};

export const getOrgType = () => {
  return window.location.pathname.split("/")[2];
};

const getMultitenant = () => {
  return window.location.pathname.split("/")[1];
};

export const LinkHelper = {
  normalize(url: string) {
    const mTenant = getMultitenant();
    const orgId = getOrgId();
    const orgType = getOrgType();

    const updatedUrl = `/${mTenant}/${orgType}/${orgId}/${url}`;
    return updatedUrl.replace("//", "/");
  },
};
