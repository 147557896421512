var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { useSlate } from "slate-react";
import {
  getAbsoluteLink,
  isBlockActive,
  isLinkActive,
  isMarkActive,
  toggleBlock,
  toggleMark,
  unwrapLink,
  wrapLink
} from "./utils";
import { colors } from "@pm-frontend/styles";
import { css, Global } from "@emotion/react";
import {
  PmEditorAlignCenter,
  PmEditorAlignJustify,
  PmEditorAlignLeft,
  PmEditorAlignRight,
  PmEditorBold,
  PmEditorInsertLink,
  PmEditorItalic,
  PmEditorOrderedList,
  PmEditorQuote,
  PmEditorUnderline,
  PmEditorUnorderedList
} from "@pm-frontend/assets/icons/components/PmEditorToolbarIcons";
const toolBarButtonStyle = {
  borderRadius: "2px 0px 0px 2px",
  border: `1px solid ${colors.neutrals.gray200}`,
  padding: "8px",
  width: "40px"
};
const BlockButton = ({ formatType, buttonContent }) => {
  const editor = useSlate();
  const active = isBlockActive(editor, formatType);
  return /* @__PURE__ */ React.createElement(
    EuiButtonEmpty,
    {
      className: "editor-toolbar-button",
      style: toolBarButtonStyle,
      "aria-label": formatType,
      isSelected: active,
      textProps: { style: { height: "20px", alignSelf: "center" } },
      onMouseDown: (event) => {
        event.preventDefault();
        toggleBlock(editor, formatType);
      }
    },
    buttonContent(active)
  );
};
const MarkButton = ({ markType, buttonContent }) => {
  const editor = useSlate();
  const active = isMarkActive(editor, markType);
  return /* @__PURE__ */ React.createElement(
    EuiButtonEmpty,
    {
      className: "editor-toolbar-button",
      style: toolBarButtonStyle,
      isSelected: active,
      "aria-label": markType,
      textProps: { style: { height: "20px", alignSelf: "center" } },
      onMouseDown: (event) => {
        event.preventDefault();
        toggleMark(editor, markType);
      }
    },
    buttonContent(active)
  );
};
export const HeadingOneButton = () => {
  return /* @__PURE__ */ React.createElement(BlockButton, { formatType: "heading-one", buttonContent: () => void 0 });
};
export const HeadingTwoButton = () => {
  return /* @__PURE__ */ React.createElement(BlockButton, { formatType: "heading-two", buttonContent: () => void 0 });
};
export const OrderedListButton = () => {
  return /* @__PURE__ */ React.createElement(
    BlockButton,
    {
      formatType: "ordered-list",
      buttonContent: (active) => /* @__PURE__ */ React.createElement(PmEditorOrderedList, __spreadValues({}, active ? { fill: colors.brand.meldBlue } : {}))
    }
  );
};
export const UnorderedListButton = () => {
  return /* @__PURE__ */ React.createElement(
    BlockButton,
    {
      formatType: "unordered-list",
      buttonContent: (active) => /* @__PURE__ */ React.createElement(PmEditorUnorderedList, __spreadValues({}, active ? { fill: colors.brand.meldBlue } : {}))
    }
  );
};
export const QuoteButton = () => {
  return /* @__PURE__ */ React.createElement(
    BlockButton,
    {
      formatType: "quote",
      buttonContent: (active) => /* @__PURE__ */ React.createElement(PmEditorQuote, __spreadValues({}, active ? { fill: colors.brand.meldBlue } : {}))
    }
  );
};
export const DividerButton = () => {
  return /* @__PURE__ */ React.createElement(BlockButton, { formatType: "divider", buttonContent: () => void 0 });
};
export const LinkButton = () => {
  const editor = useSlate();
  const active = isLinkActive(editor);
  return /* @__PURE__ */ React.createElement(
    EuiButtonEmpty,
    {
      className: "editor-toolbar-button",
      style: toolBarButtonStyle,
      "aria-label": "link",
      textProps: { style: { height: "20px", alignSelf: "center" } },
      onMouseDown: (event) => {
        event.preventDefault();
        if (active) {
          unwrapLink(editor);
        } else {
          const url = window.prompt("Enter the URL of the link:");
          if (!url || !editor.selection) {
            return;
          }
          wrapLink(editor, getAbsoluteLink(url));
        }
      }
    },
    /* @__PURE__ */ React.createElement(PmEditorInsertLink, { fill: active ? colors.brand.meldBlue : void 0 })
  );
};
export const AlignLeftButton = () => {
  return /* @__PURE__ */ React.createElement(
    BlockButton,
    {
      formatType: "left",
      buttonContent: (active) => /* @__PURE__ */ React.createElement(PmEditorAlignLeft, __spreadValues({}, active ? { fill: colors.brand.meldBlue } : {}))
    }
  );
};
export const AlignCenterButton = () => {
  return /* @__PURE__ */ React.createElement(
    BlockButton,
    {
      formatType: "center",
      buttonContent: (active) => /* @__PURE__ */ React.createElement(PmEditorAlignCenter, __spreadValues({}, active ? { fill: colors.brand.meldBlue } : {}))
    }
  );
};
export const AlignRightButton = () => {
  return /* @__PURE__ */ React.createElement(
    BlockButton,
    {
      formatType: "right",
      buttonContent: (active) => /* @__PURE__ */ React.createElement(PmEditorAlignRight, __spreadValues({}, active ? { fill: colors.brand.meldBlue } : {}))
    }
  );
};
export const AlignJustifyButton = () => {
  return /* @__PURE__ */ React.createElement(
    BlockButton,
    {
      formatType: "justify",
      buttonContent: (active) => /* @__PURE__ */ React.createElement(PmEditorAlignJustify, __spreadValues({}, active ? { fill: colors.brand.meldBlue } : {}))
    }
  );
};
export const BoldButton = () => {
  return /* @__PURE__ */ React.createElement(
    MarkButton,
    {
      markType: "bold",
      buttonContent: (active) => /* @__PURE__ */ React.createElement(PmEditorBold, __spreadValues({}, active ? { fill: colors.brand.meldBlue } : {}))
    }
  );
};
export const ItalicButton = () => {
  return /* @__PURE__ */ React.createElement(
    MarkButton,
    {
      markType: "italic",
      buttonContent: (active) => /* @__PURE__ */ React.createElement(PmEditorItalic, __spreadValues({}, active ? { fill: colors.brand.meldBlue } : {}))
    }
  );
};
export const UnderlineButton = () => {
  return /* @__PURE__ */ React.createElement(
    MarkButton,
    {
      markType: "underline",
      buttonContent: (active) => /* @__PURE__ */ React.createElement(PmEditorUnderline, __spreadValues({}, active ? { fill: colors.brand.meldBlue } : {}))
    }
  );
};
export const CodeButton = () => {
  return /* @__PURE__ */ React.createElement(MarkButton, { markType: "code", buttonContent: () => void 0 });
};
const Toolbar = () => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Global,
    {
      styles: css`
          .editor-toolbar-button {
            inline-size: 20px;
            block-size: 20px;
            padding: 0px !important;
          }
        `
    }
  ), /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "flexStart", style: { gap: "12px" } }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "flexStart", gutterSize: "none" }, /* @__PURE__ */ React.createElement(BoldButton, null), /* @__PURE__ */ React.createElement(ItalicButton, null), /* @__PURE__ */ React.createElement(UnderlineButton, null))), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "flexStart", gutterSize: "none" }, /* @__PURE__ */ React.createElement(QuoteButton, null), /* @__PURE__ */ React.createElement(OrderedListButton, null), /* @__PURE__ */ React.createElement(UnorderedListButton, null))), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "flexStart", gutterSize: "none" }, /* @__PURE__ */ React.createElement(AlignLeftButton, null), /* @__PURE__ */ React.createElement(AlignCenterButton, null), /* @__PURE__ */ React.createElement(AlignRightButton, null), /* @__PURE__ */ React.createElement(AlignJustifyButton, null))), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, alignItems: "flexStart", gutterSize: "none" }, /* @__PURE__ */ React.createElement(LinkButton, null)))));
};
export { Toolbar };
