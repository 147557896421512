const NAMELESS_USER = "Nameless User";
const PHONELESS_USER = "None Provided";
const EMAILLESS_USER = "None Provided";

export const getFullName = (agent?: { first_name?: string; last_name?: string }, emptyName = NAMELESS_USER): string => {
  if (!agent) {
    return emptyName;
  }

  const fullName = [agent.first_name, agent.last_name].filter(Boolean);

  if (fullName.length > 0) {
    return fullName.join(" ");
  } else {
    return emptyName;
  }
};

export const getAgentContactNumber = (
  agent?: { contact?: { cell_phone?: string; home_phone?: string; business_phone?: string } },
  emptyPhoneNumber = PHONELESS_USER
): string => {
  if (agent?.contact?.cell_phone && agent?.contact?.cell_phone !== "") {
    return agent.contact.cell_phone;
  } else if (agent?.contact?.home_phone && agent?.contact?.home_phone !== "") {
    return agent.contact.home_phone;
  } else if (agent?.contact?.business_phone && agent?.contact?.business_phone !== "") {
    return agent.contact.business_phone;
  }
  return emptyPhoneNumber;
};

export const getAgentEmail = (agent?: { user?: { email?: string } }, emptyEmail = EMAILLESS_USER): string => {
  if (agent?.user?.email && agent.user.email !== "") {
    return agent.user.email;
  }
  return emptyEmail;
};
