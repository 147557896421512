// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

// this file exists to share UI Redesign configuration accross different parts of the site
// because we aren't wrapping the the application in the top-level
// This should be removed someday

const MAX_NUMBER_OF_RETRIES = 0;

import { QueryClient, onlineManager } from "@tanstack/react-query";

import Features from "@pm-assets/js/common/feature-flags";

/**
 * This should be removed when we upgrade to TanStack v5.
 * If we ever need offline behavior, this needs to be removed.
 * The reason for the below event listener is as follows:
 * There were several Google Chrome users that experienced where any pages related to TanStack
 * were in a constant state of loading and nothing is being fetched when Google Chrome auto
 * updates that jettisoned a browser to restart.
 * Refer to this bug ticket :) -> https://propertymeld.atlassian.net/browse/AA-3826
 * */
onlineManager.setEventListener((setOnline) => setOnline(true));

class SingletonQueryClient {
  static _instance: SingletonQueryClient;
  private queryClient: QueryClient;

  constructor() {
    if (SingletonQueryClient._instance) {
      return SingletonQueryClient._instance;
    }
    SingletonQueryClient._instance = this;
    this.queryClient = new QueryClient({
      //  can be overridden on a per-query basis
      defaultOptions: {
        queries: {
          retry: MAX_NUMBER_OF_RETRIES,
          staleTime: Features.getQueryStaleTime(),
          refetchOnWindowFocus: Features.isRefetchOnWindowFocusEnabled(),
        },
      },
    });
  }

  getQueryClient() {
    return this.queryClient;
  }
}

export { SingletonQueryClient };
