import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIconTip, EuiLink, EuiText, EuiTextColor } from "@elastic/eui";

import { colors } from "@pm-frontend/styles";
import { Link } from "react-router-dom";
import Linkify from "react-linkify";
import URL from "@pm-shared/utils/url";

type TitleAction = {
  text: string;
  dataTestId?: string;
  enabled?: boolean;
} & (
  | {
      href: string;
    }
  | {
      onClick: () => void;
    }
);

interface ListItem {
  title: string | JSX.Element;
  titleHelpTooltip?: string;
  titleAction?: TitleAction | TitleAction[];
  titleActionAlignment?: "left" | "right";
  description: string | JSX.Element | Array<string | JSX.Element>;
  descriptionSeeAllOnClick?: () => void;
  dataTestId?: string;
  titleDataTestId?: string;
  enabled?: boolean;
  displayAll?: boolean;
}

interface PmDescriptionListProps {
  listItems: ListItem[];
  gapInPx?: number;
  "data-testid"?: string;
}

interface TransformedListItems {
  title: React.ReactNode;
  description: JSX.Element | JSX.Element[];
  descriptionIsArray: boolean;
  descriptionSeeAllOnClick: (() => void) | undefined;
  dataTestId?: string;
  titleDataTestId?: string;
  displayAll?: boolean;
}

const seeAllLink = (onClick: (() => void) | undefined, dataTestId: { "data-testid"?: string }) => {
  const onClickObj = typeof onClick === "function" ? { onClick } : {};
  return (
    <EuiLink
      {...onClickObj}
      color="primary"
      style={{ fontWeight: 700, width: "max-content" }}
      data-testid={dataTestId ? dataTestId["data-testid"] + "-see-all-link" : undefined}
    >
      See All
    </EuiLink>
  );
};

const getSingleTitleAction = (action: TitleAction): React.ReactNode => {
  if ("onClick" in action) {
    return (
      <EuiLink onClick={action.onClick} data-testid={action.dataTestId}>
        <EuiText size="s">{action.text}</EuiText>
      </EuiLink>
    );
  } else if ("href" in action) {
    return (
      <Link to={action.href}>
        <EuiLink data-testid={action.dataTestId}>
          <EuiText size="s">{action.text}</EuiText>
        </EuiLink>
      </Link>
    );
  }
};

const transformListItemTitle = (item: PmDescriptionListProps["listItems"][number]): React.ReactNode => {
  let titleText: JSX.Element;
  if (typeof item.title === "string") {
    titleText = (
      <span>
        <EuiText
          size="s"
          color={colors.neutrals.gray800}
          style={{ fontWeight: 700 }}
          data-testid={item.titleDataTestId}
        >
          {item.title}
        </EuiText>
      </span>
    );
  } else {
    titleText = item.title;
  }

  let titleActions: React.ReactNode;
  if (Array.isArray(item.titleAction)) {
    titleActions = (
      <span>
        {item.titleAction
          // eslint-disable-next-line no-prototype-builtins
          .filter((action) => !action.hasOwnProperty("enabled") || action.enabled)
          .map((action, index, arr) => (
            <React.Fragment key={action.text}>
              {getSingleTitleAction(action)}
              {index < arr.length - 1 && <span style={{ margin: "0 4px" }}>{" • "}</span>}
            </React.Fragment>
          ))}
      </span>
    );
    // eslint-disable-next-line no-prototype-builtins
  } else if (item.titleAction && (!item.titleAction.hasOwnProperty("enabled") || item.titleAction.enabled)) {
    titleActions = getSingleTitleAction(item.titleAction);
  }
  const titleTooltip = item.titleHelpTooltip ? (
    <EuiIconTip
      type={URL.getStatic("icons/questionmark.svg")}
      color={colors.neutrals.gray800}
      content={item.titleHelpTooltip}
      iconProps={{
        className: "eui-alignTop",
        size: "s",
      }}
    />
  ) : null;

  return (
    <EuiFlexGroup
      direction="row"
      alignItems="center"
      justifyContent={item.titleActionAlignment === "right" ? "spaceBetween" : "flexStart"}
      responsive={false}
      style={{ gap: "16px" }}
    >
      <EuiFlexItem grow={false}>
        <EuiFlexGroup
          direction="row"
          alignItems="center"
          justifyContent="flexStart"
          responsive={false}
          style={{ gap: "6px" }}
        >
          <EuiFlexItem grow={false}>{titleText}</EuiFlexItem>
          {titleTooltip && <EuiFlexItem grow={false}>{titleTooltip}</EuiFlexItem>}
        </EuiFlexGroup>
      </EuiFlexItem>
      {titleActions && <EuiFlexItem grow={false}>{titleActions}</EuiFlexItem>}
    </EuiFlexGroup>
  );
};

const transformListItemDescription = (value: string | JSX.Element): JSX.Element => {
  if (typeof value === "string") {
    return (
      <EuiText size="s" color={colors.neutrals.gray800} className="preWhiteSpace" style={{ wordWrap: "break-word" }}>
        {value}
      </EuiText>
    );
  } else {
    return value;
  }
};

const transformListItems = (listItems: PmDescriptionListProps["listItems"]): TransformedListItems[] => {
  return listItems.map((item) => {
    const newTitle = transformListItemTitle(item);
    let descriptionIsArray: boolean;

    let newDescription: JSX.Element | JSX.Element[];
    if (!Array.isArray(item.description)) {
      descriptionIsArray = false;
      newDescription = transformListItemDescription(item.description);
    } else {
      descriptionIsArray = true;
      newDescription = item.description.map(transformListItemDescription);
    }
    return {
      title: newTitle,
      description: newDescription,
      descriptionIsArray,
      dataTestId: item.dataTestId,
      descriptionSeeAllOnClick: item.descriptionSeeAllOnClick,
      displayAll: item.displayAll,
    };
  });
};

// Inspired by EuiDescriptionList, but with flexbox for more layout flexibility
const PmDescriptionList = ({ listItems, gapInPx = 16, "data-testid": listDataTestId }: PmDescriptionListProps) => {
  // note that not setting an enabled prop is interpreted as being 'enabled'
  // eslint-disable-next-line no-prototype-builtins
  const filteredListItems = listItems.filter((item) => !item.hasOwnProperty("enabled") || item.enabled);
  const mapDescriptionToFlexItems = (
    description: JSX.Element | JSX.Element[],
    descriptionSeeAllOnClick: (() => void) | undefined,
    dataTestId: string | undefined,
    displayAll: boolean = false
  ): JSX.Element => {
    const dataTestIdProp = dataTestId ? { "data-testid": dataTestId } : {};
    if (!Array.isArray(description)) {
      return (
        <Linkify className="inline-link">
          <EuiFlexItem grow={false} {...dataTestIdProp}>
            <dd>{description}</dd>
          </EuiFlexItem>
        </Linkify>
      );
    }
    return (
      <dd>
        <EuiFlexItem grow={false} {...dataTestIdProp}>
          <EuiFlexGroup direction="column" gutterSize="none" alignItems="flexStart">
            {description.map((item, index) => {
              if (index >= 2 && displayAll === false) {
                return null;
              }

              /* I believe it is safe to use the index as a key here because the order will never change */

              return (
                <EuiFlexItem key={index} grow={false}>
                  {item}
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
        </EuiFlexItem>
        {description.length > 2 && displayAll === false ? (
          <EuiFlexItem grow={false}>{seeAllLink(descriptionSeeAllOnClick, dataTestIdProp)}</EuiFlexItem>
        ) : null}
      </dd>
    );
  };
  return (
    <dl>
      <EuiFlexGroup
        direction="column"
        alignItems="stretch"
        style={{ gap: gapInPx, wordBreak: "break-word" }}
        data-testid={listDataTestId}
      >
        {transformListItems(filteredListItems).map((listItem, index) => {
          return (
            /* I believe it is safe to use the index as a key here because the order will never change */
            <EuiFlexItem grow={true} key={index}>
              <EuiFlexGroup direction="column" gutterSize="xs" alignItems="stretch" justifyContent="spaceBetween">
                <EuiFlexItem grow={true}>
                  <EuiTextColor color={colors.neutrals.gray800}>
                    <dt>{listItem.title}</dt>
                  </EuiTextColor>
                </EuiFlexItem>
                <EuiTextColor color={colors.neutrals.gray800}>
                  {mapDescriptionToFlexItems(
                    listItem.description,
                    listItem.descriptionSeeAllOnClick,
                    listItem.dataTestId,
                    listItem.displayAll
                  )}
                </EuiTextColor>
              </EuiFlexGroup>
            </EuiFlexItem>
          );
        })}
      </EuiFlexGroup>
    </dl>
  );
};

export { PmDescriptionList, PmDescriptionListProps, ListItem };
