import { AllVendorsListRegisteredOnlyFalse } from "@pm-frontend/shared/types/api/maintenance/api";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { apiFetch } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-shared/utils/link";
import { useQuery } from "@tanstack/react-query";

const AllMaintenanceKeys = {
  all: ["all-maintenance"],
  allMaint: (registeredOnly: boolean) => [...AllMaintenanceKeys.all, "registered only", registeredOnly],
} as const;

const useGetAllMaintenance = (
  { registeredOnly, refetchOnMount }: { registeredOnly?: boolean; refetchOnMount?: boolean } = {
    registeredOnly: false,
    refetchOnMount: true,
  }
) => {
  return useQuery<AllVendorsListRegisteredOnlyFalse>({
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.maintenanceList) + `?registeredOnly=${!!registeredOnly}`),
    queryKey: AllMaintenanceKeys.allMaint(!!registeredOnly),
    cacheTime: Infinity,
    refetchOnMount,
  });
};

export { useGetAllMaintenance };
