import { LinkHelper } from "@pm-shared/utils/link";
import { RouteUrls } from "./route-urls";

interface GetFullAddressWithPropertyNameArrayUnit
  extends Omit<GetPropertyNameDistinctFromLine1UnitProps, "display_address"> {
  display_address: GetPropertyNameDistinctFromLine1UnitProps["display_address"] &
    GetFormattedCityStateZipProps["display_address"] & {
      line_2?: string;
      line_3?: string;
    };
}

export function getFullAddressWithPropertyNameArray(unit: GetFullAddressWithPropertyNameArrayUnit): string[] {
  return [
    getPropertyNameDistinctFromLine1(unit),
    getDisplayStreetAndUnit(unit),
    unit.display_address.line_2,
    unit.display_address.line_3,
    getFormattedCityStateZip(unit),
  ].filter(Boolean) as string[];
}

interface GetPropertyNameDistinctFromLine1UnitProps {
  prop?: {
    property_name?: string;
    line_1?: string;
  };
  display_address: {
    property_name?: string;
    line_1?: string;
  };
}

export function getPropertyNameDistinctFromLine1(unit: GetPropertyNameDistinctFromLine1UnitProps): string {
  if (unit.prop?.property_name && unit.prop.property_name !== unit.prop.line_1) {
    return unit.prop.property_name;
  }
  if (unit.display_address.property_name === unit.display_address.line_1) {
    return "";
  }
  return unit.display_address.property_name || "";
}

export function getDisplayStreetAndUnit(unit: GetDisplayStreetAndUnitProps): string {
  return [unit.display_address.line_1, getFormattedUnit(unit)].join(", ").trim();
}

interface GetDisplayStreetAndUnitProps extends GetFormattedUnitProps {
  display_address: {
    line_1?: string;
  };
}

type GetPropertyNameDisplayStreetAndUnitProps = GetPropertyNameDistinctFromLine1UnitProps & GetFormattedUnitProps;

export function getPropertyNameDisplayStreetAndUnit(unit: GetPropertyNameDisplayStreetAndUnitProps): string {
  return [getPropertyNameDistinctFromLine1(unit), unit.display_address.line_1, getFormattedUnit(unit)]
    .filter(Boolean)
    .join(", ")
    .trim();
}

interface GetFormattedUnitProps {
  unit?: string;
  room?: string;
  department?: string;
}

export function getFormattedUnit(unit: GetFormattedUnitProps): string {
  return (
    [
      { prefix: "UNIT", number: unit.unit },
      { prefix: "RM", number: unit.room },
      { prefix: "DEPT", number: unit.department },
    ]
      .filter((designator) => {
        return !!designator.number;
      })
      .map((designator) => {
        return [designator.prefix, designator.number].join(" ");
      })
      .join(", ") || ""
  );
}
interface GetFormattedCityStateZipProps {
  display_address: {
    city?: string;
    county_province?: string;
    postcode?: string;
  };
}

export function getFormattedCityStateZip(unit: GetFormattedCityStateZipProps): string {
  if (unit.display_address.city) {
    return `${unit.display_address.city}, ${unit.display_address.county_province} ${unit.display_address.postcode}`;
  }

  return `${unit.display_address.county_province} ${unit.display_address.postcode}`;
}

interface GetTwoOrThreeLineUnitNameAddressProps {
  display_address: GetFormattedCityStateZipProps["display_address"] &
    GetPropertyNameDistinctFromLine1UnitProps["display_address"] & {
      line_2?: string;
      line_3?: string;
    };
}

export function getTwoOrThreeLineUnitNameAddress(unit: GetTwoOrThreeLineUnitNameAddressProps): string {
  const addressLine = [unit.display_address.line_1, unit.display_address.line_2, unit.display_address.line_3]
    .filter(Boolean)
    .join(", ");
  return [getPropertyNameDistinctFromLine1(unit), addressLine, getFormattedCityStateZip(unit)]
    .filter(Boolean)
    .join("\n");
}

export const getUnitDetailsLink = (
  unitId: number | string,
  tab?: "summary" | "melds" | "invoices" | "contacts" | "edit"
): string => {
  if (!tab) {
    return LinkHelper.normalize(RouteUrls.unitDetail({ id: unitId }));
  } else if (tab === "invoices") {
    return LinkHelper.normalize(RouteUrls.unitDetailInvoices({ id: unitId }));
  } else if (tab === "contacts") {
    return LinkHelper.normalize(RouteUrls.unitDetailContacts({ id: unitId }));
  } else if (tab === "edit") {
    return LinkHelper.normalize(RouteUrls.unitDetailEdit({ id: unitId }));
  }
  return LinkHelper.normalize(RouteUrls.unitDetail({ id: unitId }));
};
