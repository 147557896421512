var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { BaseURLFilter } from "../BaseFilterClasses";
const _BaseSavedFiltersFilterClass = class extends BaseURLFilter {
  constructor({
    config,
    overrides,
    onApplyAdditionalOnClick
  }) {
    super({
      config: __spreadProps(__spreadValues({}, config), {
        allowedOperators: [],
        componentProps: null,
        queryParamKeyPrefix: "saved_filter",
        filterName: "saved_filters",
        text: "Saved Filters"
      }),
      overrides,
      onApplyAdditionalOnClick
    });
  }
  deleteAllParamValues(props) {
    const searchParams = props.paramsToMutate || new URLSearchParams(props.location.search);
    const key = this.getFullQueryParamKey();
    searchParams.delete(key);
    return searchParams;
  }
  getCurrentlyAppliedValue({ location }) {
    const currentSearchParams = new URLSearchParams(location.search);
    const appliedFilterId = currentSearchParams.get("saved_filter");
    const options = this.getDefaultMappedOptions(appliedFilterId);
    return {
      filterType: _BaseSavedFiltersFilterClass.type,
      options
    };
  }
  getDefaultMappedOptions(savedFilterId) {
    return this.getOptions().map((opt) => {
      if (opt.isGroupLabel) {
        return {
          label: opt.label,
          isGroupLabel: true
        };
      } else {
        return {
          label: opt.label,
          checked: savedFilterId === opt.queryParamValue ? "on" : void 0
        };
      }
    });
  }
  applyNewFilter({
    location,
    history,
    selectedOptions,
    closePopover
  }) {
    var _a;
    const searchParams = new URLSearchParams(location.search);
    const key = this.getFullQueryParamKey();
    for (const filter of this.getOtherFilters()) {
      filter.deleteAllParamValues({ paramsToMutate: searchParams });
    }
    const value = selectedOptions.filter((mappedOption) => mappedOption.checked === "on").map((mappedOption) => {
      var _a2;
      return (_a2 = this.getOptions().find((opt) => opt.label === mappedOption.label)) == null ? void 0 : _a2.queryParamValue;
    }).filter(Boolean)[0];
    if (value) {
      searchParams.set(key, value);
      (_a = this.getOnApplyAdditionalOnClick()) == null ? void 0 : _a(searchParams);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    }
    closePopover();
  }
  getAppliedFilterCount({ currentValue }) {
    return currentValue.options.filter((opt) => opt.checked === "on").length;
  }
  getQueryParamValuesFromURL(props) {
    const currentURLParams = props.existingParams || new URLSearchParams(props.location.search);
    const newParams = props.paramsToMutate || new URLSearchParams();
    const paramKey = this.getFullQueryParamKey();
    const value = currentURLParams.get(paramKey);
    if (value) {
      newParams.set(paramKey, value);
    }
    return newParams;
  }
  isValidParamKey(key) {
    return this.getQueryParamKeyPrefix() === key;
  }
  /*
   * The saved filter objects don't have the '[]' suffix that the query params do
   */
  getFullQueryParamKey() {
    return this.getQueryParamKeyPrefix();
  }
  getOtherFilters() {
    return this.getConfig().otherFilters;
  }
  getOptions() {
    return this.getConfig().options;
  }
};
export let BaseSavedFiltersFilterClass = _BaseSavedFiltersFilterClass;
BaseSavedFiltersFilterClass.type = "saved_filters_filter";
