import { MeldManagerStatusLabels, MeldStatus } from "@pm-frontend/shared/types/meld";
import { getCategoryLabel } from "@pm-frontend/shared/utils/meld-utils";
import { WorkCategory } from "@pm-frontend/shared/utils/statuses";
import { FilterClassTypes } from "../BaseFilterClasses";
import { PmDateFilterProps } from "../PmDateFilter/BaseDateFilterClass";
import { PmDecimalFilterProps } from "../PmDecimalFilter/BaseDecimalFilterClass";
import { PmSavedFiltersFilterProps } from "../PmSavedFiltersFilter/BaseSavedFiltersFilterClass";
import { PmSelectableFilterProps } from "../PmSelectableFilter/BaseSelectableFilterClass";
import { WorkType } from "@pm-frontend/shared/utils/statuses";
import { getWorkTypeLabel } from "@pm-frontend/shared/utils/meld-utils";
import { BaseSortFilterClass, PmSortFilterProps } from "../PmSortFilter/BaseSortFilterClass";
import { BaseComboboxFilterClass, PmComboboxFilterProps } from "../PmComboboxFilter/BaseComboboxFilterClass";
import { BaseDateFilterClass } from "../PmDateFilter/BaseDateFilterClass";
import { BaseDecimalFilterClass } from "../PmDecimalFilter/BaseDecimalFilterClass";
import { BaseSelectableFilterClass } from "../PmSelectableFilter/BaseSelectableFilterClass";
import { AllVendorsListRegisteredOnlyFalse } from "@pm-frontend/shared/types/api/maintenance/api";
import { ManagementAgentSerializer } from "@pm-frontend/shared/types/api/manager/serializers/serializers";
import { VendorFilterSerializer } from "@pm-frontend/shared/types/api/vendor/serializers/serializers";
import { VendorInviteMaintenanceSerializer } from "@pm-frontend/shared/types/api/vendor/serializers/vendor_invite_maintenance_serializer";
import { getFullName } from "@pm-frontend/shared/utils/agent-utils";

const MeldFilterQueryKeysPrefix: Record<MeldFilterTypes, string> = {
  assignedMaintenance: "maint",
  categories: "categories",
  coordinator: "coordinator",
  creation_date: "created",
  maint_type: "maint_type",
  priority: "priority",
  sort: "ordering",
  status: "status",
  tpr: "tpr",
  work_hours_logged: "wl",
  work_type: "work_type",
} as const;

// we need to append '[]' to certain keys
export const MeldFilterQueryKeys: Record<MeldFilterTypes, string> = {
  ...MeldFilterQueryKeysPrefix,
  priority: MeldFilterQueryKeysPrefix.priority + "[]",
  status: MeldFilterQueryKeysPrefix.status + "[]",
  work_type: MeldFilterQueryKeysPrefix.work_type + "[]",
  coordinator: MeldFilterQueryKeysPrefix.coordinator + "[]",
  maint_type: MeldFilterQueryKeysPrefix.maint_type + "[]",
} as const;

// used when using BaseSavedFiltersFilterClass
export const getMeldSavedFilterConfig = ({
  savedFilters,
  otherFilters,
}: {
  savedFilters: Array<{ id: number; name: string; private: boolean }>;
  otherFilters: Array<FilterClassTypes | BaseSortFilterClass>;
}): PmSavedFiltersFilterProps => {
  const privateOptions = savedFilters
    .filter((opt) => opt.private)
    .map((opt) => ({ label: opt.name, queryParamValue: opt.id.toString(), isGroupLabel: false }));
  const sharedOptions = savedFilters
    .filter((opt) => !opt.private)
    .map((opt) => ({ label: opt.name, queryParamValue: opt.id.toString(), isGroupLabel: false }));
  return {
    options: [
      { label: "Shared", isGroupLabel: true, queryParamValue: "" },
      ...sharedOptions,
      { label: "Private", isGroupLabel: true, queryParamValue: "" },
      ...privateOptions,
    ],
    popoverWidth: "300px",
    alwaysShow: true,
    otherFilters,
  };
};

export const MeldFilterSortValues = {
  "Created: Old": "created",
  "Created: Recent": "-created",
  "Meld: A-Z": "brief_description",
  "Meld: Z-A": "-brief_description",
  "Scheduled: Soon": "scheduled",
  "Scheduled: Later": "-scheduled",
  "Updated: Old": "updated",
  "Updated: Recent": "-updated",
} as const;

type SortFilterTypes = "sort";

const MeldFilterSortConfigs: Record<SortFilterTypes, PmSortFilterProps> = {
  sort: {
    filterName: "sort_melds",
    options: [
      { label: "Created: Old", queryParamValue: MeldFilterSortValues["Created: Old"] },
      { label: "Created: Recent", queryParamValue: MeldFilterSortValues["Created: Recent"] },
      { label: "Meld: A-Z", queryParamValue: MeldFilterSortValues["Meld: A-Z"] },
      { label: "Meld: Z-A", queryParamValue: MeldFilterSortValues["Meld: Z-A"] },
      { label: "Scheduled: Soon", queryParamValue: MeldFilterSortValues["Scheduled: Soon"] },
      { label: "Scheduled: Later", queryParamValue: MeldFilterSortValues["Scheduled: Later"] },
      { label: "Updated: Old", queryParamValue: MeldFilterSortValues["Updated: Old"] },
      { label: "Updated: Recent", queryParamValue: MeldFilterSortValues["Updated: Recent"] },
    ],
    defaultOption: "Created: Old",
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.sort,
    alwaysShow: true,
    popoverWidth: "300px",
  },
};

type SelectableFilterTypes = "priority" | "maint_type" | "status" | "tpr" | "work_type";

const MeldSelectableFilterConfigs: Record<SelectableFilterTypes, PmSelectableFilterProps> = {
  priority: {
    text: "Priority",
    filterName: "priority",
    options: [
      { label: "High", queryParamValue: "HIGH" },
      { label: "Medium", queryParamValue: "MEDIUM" },
      { label: "Low", queryParamValue: "LOW" },
    ],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.priority,
    componentProps: {
      searchable: false,
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of", "None of"],
  },
  maint_type: {
    text: "Maintenance Type",
    filterName: "maintenance_type",
    options: [
      { label: "External", queryParamValue: "2" },
      { label: "Internal", queryParamValue: "1" },
    ],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.maint_type,
    componentProps: {
      searchable: false,
      singleSelection: true,
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of"],
  },
  status: {
    text: "Status",
    filterName: "status",
    options: Object.keys(MeldStatus)
      .map((status: MeldStatus) => ({
        label: MeldManagerStatusLabels[status],
        queryParamValue: status,
        // this is an unused status
      }))
      .filter((opt) => opt.queryParamValue !== MeldStatus.OPEN),
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.status,
    componentProps: {
      searchable: true,
      singleSelection: false,
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of"],
  },
  tpr: {
    text: "Resident Required",
    popoverWidth: "300px",
    filterName: "tpr",
    options: [
      { label: "Required", queryParamValue: "true" },
      { label: "Not Required", queryParamValue: "false" },
    ],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.tpr,
    allowedOperators: ["Any of"],
    componentProps: {
      searchable: false,
      singleSelection: true,
    },
  },
  work_type: {
    text: "Work Type",
    filterName: "work_type",
    options: Object.keys(WorkType).map((workType: WorkType) => ({
      label: getWorkTypeLabel(workType),
      queryParamValue: workType,
    })),
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.work_type,
    componentProps: {
      searchable: true,
      singleSelection: false,
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of", "None of"],
  },
};

type ComboboxFilterTypes = "coordinator" | "categories" | "assignedMaintenance";

const MeldComboboxFilterConfigs: Record<ComboboxFilterTypes, PmComboboxFilterProps> = {
  assignedMaintenance: {
    text: "Assigned Maintenance",
    popoverWidth: "300px",
    filterName: "meld_assigned_maint",
    // this property must be overriden when constructing the BaseComboboxFilterClass
    options: [],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.assignedMaintenance,
    allowedOperators: ["Any of"],
    componentProps: {
      placeholder: "Filter by assigned maintenance",
    },
  },
  categories: {
    text: "Categories",
    popoverWidth: "300px",
    filterName: "meld_category",
    options: Object.values(WorkCategory).map((wc: WorkCategory) => ({
      label: getCategoryLabel(wc),
      queryParamValue: wc,
      created: undefined,
    })),
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.categories,
    allowedOperators: ["Any of"],
    componentProps: {
      placeholder: "Filter by work category",
    },
  },
  coordinator: {
    filterName: "coordinator",
    text: "Coordinator",
    // this property must be overriden when constructing the BaseComboboxFilterClass
    options: [],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.coordinator,
    popoverWidth: "300px",
    componentProps: {
      placeholder: "Filter by coordinator",
    },
    allowedOperators: ["Any of", "None of", "Missing", "Present"],
  },
};

type DateFilterTypes = "creation_date";

const MeldFilterConfigsDate: Record<DateFilterTypes, PmDateFilterProps> = {
  creation_date: {
    text: "Creation Date",
    filterName: "creation_date",
    componentProps: null,
    alwaysShow: false,
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.creation_date,
    allowedOperators: ["In range", "Not in range", "In the past", "Older than", "Equal to", "Before", "After"],
    popoverWidth: "300px",
    suffixOverrides: { _gte: "__gte", _lte: "__lte", _offset_lte: "__offset_lte", _interval: "__interval" },
  },
};

type DecimalFilterTypes = "work_hours_logged";

const MeldDecimalFilterConfigs: Record<DecimalFilterTypes, PmDecimalFilterProps> = {
  work_hours_logged: {
    text: "Hours logged",
    filterName: "work_hours_logged",
    numberUnitLabel: "Hours",
    popoverWidth: "300px",
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.work_hours_logged,
    allowedOperators: [
      "Greater or equal to",
      "Lesser or equal to",
      "Between",
      "Missing",
      "Present",
      "Equal to",
      "Not equal to",
    ],
    suffixOverrides: { _gte: "__gte", _lte: "__lte" },
    componentProps: null,
    alwaysShow: false,
  },
};

export interface MeldFilterConfigsType {
  selectable: Record<SelectableFilterTypes, PmSelectableFilterProps>;
  combobox: Record<ComboboxFilterTypes, PmComboboxFilterProps>;
  decimal: Record<DecimalFilterTypes, PmDecimalFilterProps>;
  date: Record<DateFilterTypes, PmDateFilterProps>;
  sort: Record<SortFilterTypes, PmSortFilterProps>;
}

/**
These config objects are used with PmFilterButtons to filter various meld lists
This allows you to selectively choose which filtering options to surface. Overrides
can be passed to the related filter constructor. 

Some filters (such as combobox) need to be constructed as a part of the component render due
to the options being dependent on backend calls (think agent/vendor filters).
Most often you can useMemo the array of filter classes in that case.

```
const MeldCalendarNearbyMeldFilters: FilterClassTypes[] = [
  new BaseSelectableFilterClass(MeldFilterConfigs.selectable.status, { alwaysShow: true }),
  new BaseSelectableFilterClass(MeldFilterConfigs.selectable.priority, { alwaysShow: true }),
  new BaseComboboxFilterClass(MeldFilterConfigs.combobox.coordinator),
  new BaseDecimalFilterClass(MeldFilterConfigs.decimal.work_hours_logged),
  new BaseDateFilterClass(MeldFilterConfigs.date.creation_date),
];

const SomeFilterComponent = () => {
    return <PmListFilters filters={filters} onSaveClick={...} />
}
```
*/
export const MeldFilterConfigs: MeldFilterConfigsType = {
  selectable: MeldSelectableFilterConfigs,
  combobox: MeldComboboxFilterConfigs,
  decimal: MeldDecimalFilterConfigs,
  date: MeldFilterConfigsDate,
  sort: MeldFilterSortConfigs,
};

type MeldFilterTypes =
  | SelectableFilterTypes
  | ComboboxFilterTypes
  | DecimalFilterTypes
  | DateFilterTypes
  | SortFilterTypes;

// make all subfields of a type partial
type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};
//
export type MeldFilterOverridesType = DeepPartial<MeldFilterConfigsType>;

/**
 Useful for using meld filter configs.

 @argument allMaint
 usually fetched via `useGetAllMaintenance`. If you are only reading from the url you can just pass []

 You probably want to useMemo this call.
 */
export function getDefaultMeldFilters({
  allMaint,
  overrides,
  onApplyAdditionalOnClick,
}: {
  allMaint: AllVendorsListRegisteredOnlyFalse;
  overrides?: DeepPartial<MeldFilterConfigsType>;
  onApplyAdditionalOnClick?: (params: URLSearchParams) => void;
}) {
  const allAgents = (allMaint || []).filter(
    (maint): maint is ManagementAgentSerializer => maint.type === "ManagementAgent"
  );
  const allVendors = (allMaint || []).filter((maint): maint is VendorFilterSerializer => maint.type === "Vendor");
  const allVendorInvites = (allMaint || []).filter(
    (maint): maint is VendorInviteMaintenanceSerializer => maint.type === "VendorInvite"
  );

  const allAgentsMapped: PmComboboxFilterProps["options"] = allAgents.map((agent) => ({
    queryParamValue: agent.id.toString(),
    label: getFullName(agent),
    created: agent.created,
  }));

  const allMaintMapped: PmComboboxFilterProps["options"] = [
    { label: "Agents", isGroupLabelOption: true, queryParamValue: "", created: undefined },
    ...allAgents.map((agent) => ({
      queryParamValue: agent.composite_id,
      label: `${getFullName(agent)} (Maintenance)`,
      created: agent.created,
    })),
    { label: "Vendors", isGroupLabelOption: true, queryParamValue: "", created: undefined },
    ...allVendors.map((vendor) => ({
      queryParamValue: vendor.composite_id,
      created: vendor.created,
      label: `${vendor.name || vendor.email || ""} (Vendor)`,
    })),
    { label: "Vendor Invites", isGroupLabelOption: true, queryParamValue: "", created: undefined },
    ...allVendorInvites.map((invite) => ({
      queryParamValue: invite.composite_id,
      created: invite.created,
      label: `${invite.name || invite.email || ""} (Invited Vendor)`,
    })),
  ];

  return {
    filters: [
      // NOTE: these are alphabetical by the filter button text
      new BaseComboboxFilterClass({
        config: MeldFilterConfigs.combobox.assignedMaintenance,
        overrides: { options: allMaintMapped, ...(overrides?.combobox?.assignedMaintenance || {}) },
        onApplyAdditionalOnClick,
      }),
      new BaseComboboxFilterClass({
        config: MeldFilterConfigs.combobox.categories,
        overrides: overrides?.combobox?.categories,
        onApplyAdditionalOnClick,
      }),
      new BaseComboboxFilterClass({
        config: MeldFilterConfigs.combobox.coordinator,
        overrides: { options: allAgentsMapped, ...(overrides?.combobox?.coordinator || {}) },
        onApplyAdditionalOnClick,
      }),
      new BaseDateFilterClass({
        config: MeldFilterConfigs.date.creation_date,
        overrides: overrides?.date?.creation_date,
        onApplyAdditionalOnClick,
      }),
      new BaseDecimalFilterClass({
        config: MeldFilterConfigs.decimal.work_hours_logged,
        overrides: overrides?.decimal?.work_hours_logged,
        onApplyAdditionalOnClick,
      }),
      new BaseSelectableFilterClass({
        config: MeldFilterConfigs.selectable.maint_type,
        overrides: overrides?.selectable?.maint_type,
        onApplyAdditionalOnClick,
      }),
      new BaseSelectableFilterClass({
        config: MeldFilterConfigs.selectable.priority,
        overrides: overrides?.selectable?.priority,
        onApplyAdditionalOnClick,
      }),
      new BaseSelectableFilterClass({
        config: MeldFilterConfigs.selectable.status,
        overrides: overrides?.selectable?.status,
        onApplyAdditionalOnClick,
      }),
      new BaseSelectableFilterClass({
        config: MeldFilterConfigs.selectable.tpr,
        overrides: overrides?.selectable?.tpr,
        onApplyAdditionalOnClick,
      }),
      new BaseSelectableFilterClass({
        config: MeldFilterConfigs.selectable.work_type,
        overrides: overrides?.selectable?.work_type,
        onApplyAdditionalOnClick,
      }),
      // TODO: scheduled date
    ],
    sortFilter: new BaseSortFilterClass({
      config: MeldFilterConfigs.sort.sort,
      overrides: overrides?.sort?.sort,
      onApplyAdditionalOnClick,
    }),
  };
}
